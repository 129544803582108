const MainKey = {
  MAP: 'map',
  ADDRESS: 'address',
  IMAGE: 'image',
  PROJECT: 'project',
  MEMBER: 'member',
  FARM_MAP: 'farmMap',
};

export const mapKeys = {
  all: [MainKey.MAP],
};

export const addressKeys = {
  all: [MainKey.ADDRESS],
};

//image-controller
export const imageKeys = {
  upload: [MainKey.IMAGE],
};

//project-controller
export const projectKeys = {
  all: [MainKey.PROJECT],
};

//member-controller
export const memberKeys = {
  all: [MainKey.MEMBER],
  projects: (memberId) => [...MainKey.MEMBER, 'projects', { ...memberId }],
};

//farmmap-controller
export const farmMapKeys = {
  all: [MainKey.FARM_MAP],
};

// 필수 !! invalidate 필요
const queryKeys = {
  MainKey,
  mapKeys,
  addressKeys,
  imageKeys,
  projectKeys,
  memberKeys,
  farmMapKeys,
};
export default queryKeys;
