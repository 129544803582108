import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const LabelValueStyle = {
  Wrap: styled.div<LabelValueProps>`
    display: flex;
    align-items: ${(p) => (p.$alignItem === 'flex-start' ? 'flex-start' : 'center')};
    &:not(:last-child) {
      margin-bottom: ${(p) => (typeof p.marginbottom === 'number' ? p.marginbottom + 'px' : '6px')};
    }
    box-sizing: border-box;
    border-bottom: ${(p) => (p.border ? '1px solid rgba(217, 217, 217, 0.62)' : 'none')};
    padding: ${(p) => (p.padding ? p.padding + 'px 0 ' + p.padding + 'px' : 0)};
  `,
  Key: styled.strong<LabelValueProps>`
    display: flex;
    align-items: center;
    min-width: ${(p) => (p.$labelWidth ? p.$labelWidth + 'px' : '74px')};
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '14px')};
    font-weight: 500;
    color: ${(p) => (p.$labelColor ? p.$labelColor : '#00b074')};
    margin-right: 4px;
  `,
  Cont: styled.div``,
  Row: styled.div`
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  `,
  Badge: styled.span`
    width: 56px;
    height: 22px;
    background: #ecfdf3;
    border-radius: 50px;
    font-weight: 500;
    color: #027a48;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  `,
  Value: styled.span<LabelValueProps>`
    display: flex;
    align-items: center;
    justify-content: ${(p) => (p.valueJustifyContent ? p.valueJustifyContent : 'flex-start')};
    flex: 1;
    word-break: keep-all;
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '14px')};
    font-weight: 500;
    color: ${(p) => (p.$valueColor ? p.$valueColor : '#3f3f3f')};
    ${(p) =>
      p.$ellipsis &&
      `
        overflow:hidden;text-overflow:ellipsis;white-space:noWrap;
      `};
  `,
};

export interface LabelValueProps {
  $labelWidth?: number;
  $labelColor?: string;
  $valueColor?: string;
  fontSize?: number;
  marginbottom?: number;
  $alignItem?: 'flex-start' | string;
  $ellipsis?: boolean;
  label?: string;
  value?: string | number | ReactNode;
  valueJustifyContent?: string;
  padding?: number;
  border?: boolean;
}

const LabelValue = ({ $labelWidth, fontSize, marginbottom, $alignItem, $ellipsis, label, value, padding, border, valueJustifyContent, $labelColor, $valueColor }: LabelValueProps) => {
  return (
    <LabelValueStyle.Wrap marginbottom={marginbottom} $alignItem={$alignItem} border={border} padding={padding}>
      <LabelValueStyle.Key $labelWidth={$labelWidth} fontSize={fontSize} $labelColor={$labelColor}>
        {label}
      </LabelValueStyle.Key>
      <LabelValueStyle.Value $ellipsis={$ellipsis} border={border} valueJustifyContent={valueJustifyContent} fontSize={fontSize} $valueColor={$valueColor}>
        {value}
      </LabelValueStyle.Value>
    </LabelValueStyle.Wrap>
  );
};

export default LabelValue;
