import React, { useEffect, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import NoData from '../components/atoms/NoData';
import IcoLot from '../assets/images/noData/ico-lot.svg';
import PagingTable from '../components/atoms/PagingTable';
import Checkbox from '../components/atoms/Checkbox';
import SearchInput from '../components/molcures/SearchInput';
import { useRecoilState } from 'recoil';
import { confirmState } from '../store/Atoms';

const Content = {
  Inner: styled.div`
    padding: 24px 0;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    > * {
      flex: 1;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
};

const PopupAddReader = ({ onClose }) => {
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dummy, setDummy] = useState([
    { id: 1, name: '오인섭', email: 'is.mv@movements.kr', checked: false },
    { id: 2, name: '오인섭', email: 'is.mv@movements.kr', checked: false },
    { id: 3, name: '오인섭', email: 'is.mv@movements.kr', checked: false },
    { id: 4, name: '오인섭', email: 'is.mv@movements.kr', checked: false },
    { id: 5, name: '오인섭', email: 'is.mv@movements.kr', checked: false },
    { id: 6, name: '오인섭', email: 'is.mv@movements.kr', checked: false },
  ]);

  //전체 선택/해제
  const onCheckAll = () => {
    const newArr = [...dummy];
    if (newArr.length === newArr.filter(({ checked }) => checked).length) {
      newArr.map((i) => {
        i.checked = false;
      });
    } else {
      newArr.map((i) => {
        i.checked = true;
      });
    }
    setDummy(newArr);
  };

  //체크박스 클릭
  const onCheck = (id) => {
    const newArr = [...dummy];
    newArr.find((i) => i.id === id)!.checked = !newArr.find((i) => i.id === id)?.checked;
    setDummy(newArr);
  };

  //체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    if (dummy.filter(({ checked }) => checked).length === dummy.length) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  }, [dummy.filter(({ checked }) => checked).length]);

  //추가하기 버튼 클릭
  const onAdd = () => {
    setConfirm({
      ...confirmState,
      show: true,
      message: '판독자를 추가하시겠습니까?',
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        //Todo 판독자 추가 API 작성 예정
        setConfirm({ ...confirm, show: false });
      },
    });
  };

  return (
    <PopupTemplate
      title={'판독자 추가'}
      width={668}
      isDimmed={true}
      onClose={onClose}
      footer={<Button width={'full'} text={`추가하기(${dummy.filter(({ checked }) => checked).length})`} onClick={onAdd} disabled={!dummy.filter(({ checked }) => checked).length} />}
      content={
        <Content.Inner>
          <SearchInput onChange={() => {}} onClick={() => {}} inputWidth={320} height={48} placeholder={'이름 또는 E-mail로 검색'} />
          <PagingTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            caption={'판독자 목록'}
            colgroup={[60, 160, 'auto']}
            totalPages={100}
            thead={
              <tr>
                <th scope={'col'}>
                  <Checkbox id={'readerListAll'} onChange={onCheckAll} checked={isAllCheck} />
                </th>
                <th scope={'col'}>이름</th>
                <th scope={'col'}>이메일</th>
              </tr>
            }
            tbody={
              <>
                {dummy.length
                  ? dummy.map((i) => {
                      return (
                        <tr key={i.id}>
                          <td>
                            <Checkbox id={'readerList_' + i.id} checked={i.checked} onChange={() => onCheck(i.id)} />
                          </td>
                          <td>{i.name}</td>
                          <td>{i.email}</td>
                        </tr>
                      );
                    })
                  : null}
              </>
            }
          />
        </Content.Inner>
      }
    />
  );
};

export default PopupAddReader;
