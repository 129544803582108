import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import IcoTotal from 'assets/images/cropInfoBox/ico-total.svg';
import Pagination from 'components/atom/Pagination';
import Checkbox from 'components/atoms/Checkbox';
import useOnChange from 'hooks/useOnChange';
import Button from '../../atoms/Button';
import IcoInfo from 'assets/images/button/ico-info.svg';
import IcoInfoDisabled from 'assets/images/button/ico-info-disabled.svg';
import IcoPic from 'assets/images/button/ico-pic.svg';
import IcoPicCompletion from 'assets/images/button/ico-pic-completion.svg';
import LabelValue from 'components/atoms/LabelValue';
import Badge, { BadgeArea } from 'components/atoms/Badge';
import ImageW from 'assets/images/image-w.png';
import ImageZ from 'assets/images/image-z.png';
import usePopupToggle from '../../../hooks/custom/usePopupToggle';
import PopupLotInfo from '../../../popup/PopupLotInfo';
import Input from 'components/atoms/Input';

import { CropInfoSt } from './_style';
import PopupLargeImage from 'popup/PopupLargeImage';
import OlCore from 'ol/OlCore2';
import RangeSlider from '../Map/RangeSlider';
import { useRecoilState } from 'recoil';
import { popupLargeImgState } from 'store/Atoms';
import CropFilter from './CropFilter';
import { createKeywordTypeNode } from 'typescript';

function CropInfoBox({ close }) {
  const cropCheckRef: any = useRef(null);
  const [cropCheckWidth, setCropCheckWidth] = useState(0);
  const [selected, setSelected] = useState(['a-1']);
  const [popupState, setPopupState] = useState({
    lotInfo: false,
  });
  const onTogglePopup = usePopupToggle(popupState, setPopupState);

  //카드 더미데이터
  // 필요한 내용 : id/이미지2개/주소/판독상태(작업완료,점유율,잔여량)/중복이미지개수/checked여부(웹단)
  const [cardData, setCardData] = useState([
    { farmId: 457703503254370, imageW: ImageW, imageZ: ImageZ, share: true, remaining: true, success: true, active: false, imgCount: '6', surveyCount: 0 },
    { farmId: 457703503254371, imageW: ImageW, imageZ: ImageZ, share: false, remaining: false, success: false, active: false, imgCount: '0', surveyCount: 0 },
    { farmId: 457703503254372, imageW: ImageW, imageZ: ImageZ, share: true, remaining: true, success: false, active: false, imgCount: '0', surveyCount: 0 },
    { farmId: 457703503254373, imageW: ImageW, imageZ: ImageZ, share: true, remaining: false, success: false, active: false, imgCount: '0', surveyCount: 0 },
    { farmId: 457703503254374, imageW: ImageW, imageZ: ImageZ, share: false, remaining: true, success: false, active: false, imgCount: '0', surveyCount: 0 },
    { farmId: 457703503254375, imageW: ImageW, imageZ: ImageZ, share: false, remaining: false, success: false, active: false, imgCount: '0', surveyCount: 0 },
    { farmId: 457703503254376, imageW: ImageW, imageZ: ImageZ, share: false, remaining: false, success: false, active: false, imgCount: '0', surveyCount: 0 },
  ]);

  //카드 전체체크여부 변수
  const [allChecked, setAllChecked] = useState(false);

  // 작물체크Wrap width를 가져오는 부분
  useEffect(() => {
    if (cropCheckRef.current) {
      const { width } = cropCheckRef.current.getBoundingClientRect();
      setCropCheckWidth(width);
    }
  }, []);

  /* 고려사항
   1) 복수이미지를 가진 카드 데이터는 단독 선택만 가능
   (전체선택 체크박스/카드개별 체크박스에서 고려해야함)
  */
  // ------------ 카드 선택 ------------//
  //카드 체크 함수
  const onClickCard = (farmId: number) => {
    setCardData((prev) => {
      return prev.map((i) => {
        return {
          ...i,
          active: i.farmId === farmId ? !i.active : i.active,
        };
      });
    });
  };

  //카드 전체선택
  const onClickAllCard = () => {
    setCardData((prev) => {
      if (prev.filter(({ active }) => active).length === prev.length) {
        return prev.map((i) => {
          return {
            ...i,
            active: false,
          };
        });
      } else {
        return prev.map((i) => {
          return {
            ...i,
            active: true,
          };
        });
      }
    });
  };

  //카드 전체선택 여부 트리거
  useEffect(() => {
    if (cardData.length !== 0) {
      if (cardData.length === cardData.filter(({ active }) => active).length) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    }
  }, [cardData]);
  // ------------ 끝 ------------//

  // ------------ 필드, 작물 체크박스 ------------//
  // 필드
  const initFormField = {
    Allfield: false,
    field1: false,
    field2: false,
    field3: false,
    field4: false,
  };
  // 작물
  const initFormCrop = {
    전체: false,
    양파: false,
    마늘: false,
    무: false,
    당근: false,
    양배추: false,
    기타: false,
  };
  const [formField, setFormField, onChangeField] = useOnChange(initFormField); // 필드
  const [formCrop, setFormCrop, onChangeCrop] = useOnChange(initFormCrop); // 작물
  const [formBefore, setFormBefore, onChangeBefore] = useOnChange(initFormCrop); // 미판독

  // 필드 - 모두 체크될때 자동으로 [전체] 체크 처리 또는 해제
  useEffect(() => {
    // 4개 모두 true인지 확인(every)
    const fieldAllTrue = Object.entries(formField)
      .filter(([key]) => key !== 'Allfield')
      .every(([key, value]) => value);

    if (fieldAllTrue && !formField['Allfield']) {
      setFormField((prev) => ({ ...prev, Allfield: true }));
    }
    if (!fieldAllTrue && formField['Allfield']) {
      setFormField((prev) => ({ ...prev, Allfield: false }));
    }
  }, [formField, setFormField]);

  // 작물 - 모두 체크될때 자동으로 [전체] 체크 처리 또는 해제
  useEffect(() => {
    // 모두 true인지 확인(every)
    const cropAllTrue = Object.entries(formCrop)
      .filter(([key]) => key !== '전체')
      .every(([key, value]) => value);

    if (cropAllTrue && !formCrop['전체']) {
      setFormCrop((prev) => ({ ...prev, 전체: true }));
    }
    if (!cropAllTrue && formCrop['전체']) {
      setFormCrop((prev) => ({ ...prev, 전체: false }));
    }
  }, [formCrop, setFormCrop]);

  // 필드 / 작물 - [전체] 선택시
  const onChangeAll = (e) => {
    const { name, checked } = e.target;

    //필지 [전체]
    if (name === 'Allfield') {
      setFormField((prev) => {
        let newform = {};
        Object.keys(prev).map((key) => (newform[key] = checked));
        return newform;
      });
    }

    //작물 [전체]
    if (name === '전체') {
      setFormCrop((prev) => {
        let newform = {};
        Object.keys(prev).map((key) => (newform[key] = checked));
        return newform;
      });
    }
  };

  // 선택된 카드 개수(전체선택 체크박스 옆에 표시용)
  const selectedCardLength = cardData?.filter(({ active }) => active)?.length;

  return (
    <>
      <CropInfoSt.Wrap cropCheckWidth={cropCheckWidth} className={!cardData.filter(({ active }) => active).length ? 'hide' : undefined}>
        <CropInfoSt.Header>
          <p className="title">작물 정보</p>
          <span className="closeBtn" onClick={close}></span>
        </CropInfoSt.Header>
        <CropInfoSt.ContainerWrap>
          <CropInfoSt.Container className={!cardData.filter(({ active }) => active).length ? 'hide' : undefined}>
            <CropFilter onChangeAll={onChangeAll} onChangeField={onChangeField} formField={formField} onChangeBefore={onChangeBefore} formBefore={formBefore} onChangeCrop={onChangeCrop} formCrop={formCrop} />

            <CropInfoSt.ContentWrap cropCheckWidth={cropCheckWidth}>
              <CropInfoSt.Content>
                {/* 전체 선택 체크박스 */}
                <div className="selectAll">
                  <Checkbox text="전체 선택" id="AllCard" name="all" onChange={onClickAllCard} checked={allChecked} />

                  <div className="selectWrap">
                    <p>선택</p>
                    <span className="num">{selectedCardLength}</span>
                  </div>
                </div>
                {/* 필터 버튼 및 페이지 표기 */}
                <div className="filterWrap">
                  <div className="btnWrap">
                    <button className="active">점유율</button>
                    <button className="active">잔여량</button>
                    <button className="active">검수완료</button>
                  </div>
                  <div className="totalWrap">
                    {/* <span>조회 결과 / </span> */}
                    <span className="total">총 {'1500'}건</span>
                  </div>
                </div>
                {/* 카드 리스트 영역 */}
                <CropInfoSt.CardWrap className="cScroll">
                  {cardData?.map((item) => {
                    return (
                      <CropInfoSt.Card className={item.active ? 'active' : undefined} key={item.farmId}>
                        <CropInfoSt.CardHead>
                          <Checkbox text={item.farmId} fontSize={16} id={`check_${item.farmId}`} $sameColor={true} onChange={() => onClickCard(item.farmId)} checked={item.active} />
                          <Button $buttonType={'small'} text={'상세'} $iconSize={16} icon={IcoInfo} $iconDisabled={IcoInfoDisabled} $iconSpacing={2} onClick={() => onTogglePopup('lotInfo')} />
                        </CropInfoSt.CardHead>
                        <CropInfoSt.CardCont>
                          {item.imgCount && <div className="imgCount">{'10'}</div>}
                          <CropInfoSt.CardImageArea>
                            <img src={item.imageW} alt={item.farmId + '_근경'} />
                            <img src={item.imageZ} alt={item.farmId + '_원경'} />
                          </CropInfoSt.CardImageArea>
                        </CropInfoSt.CardCont>
                        <CropInfoSt.CardFoot>
                          <CropInfoSt.CardLabelValueArea>
                            <LabelValue $ellipsis={true} label={'판독 회차'} value={item.surveyCount} valueJustifyContent={'flex-end'} />
                            <LabelValue
                              valueJustifyContent={'flex-end'}
                              label={'판독'}
                              value={
                                <BadgeArea>
                                  {item.success ? <Badge type={'orange'} text={'검수완료'} /> : null}
                                  {!item.success && item.share ? <Badge type={'sky'} text={'점유율'} /> : null}
                                  {!item.success && item.remaining ? <Badge type={'green'} text={'잔여량'} /> : null}
                                  {!item.success && !item.remaining && !item.share ? <Badge text={'-'} /> : null}
                                </BadgeArea>
                              }
                            />
                          </CropInfoSt.CardLabelValueArea>
                        </CropInfoSt.CardFoot>
                      </CropInfoSt.Card>
                    );
                  })}
                </CropInfoSt.CardWrap>
              </CropInfoSt.Content>
              {/* 페이지네이션 영역 */}
              <div className="pageListWrap">
                <Pagination />
              </div>
            </CropInfoSt.ContentWrap>

            {/* 선택된 카드가 있을때 오픈 */}
            {cardData.filter(({ active }) => active).length > 0 && (
              <>
                <ActiveArea hide={!cardData.filter(({ active }) => active).length} cardData={cardData} />
                <DataEnterArea hide={!cardData.filter(({ active }) => active).length} cardData={cardData} />
              </>
            )}
          </CropInfoSt.Container>
        </CropInfoSt.ContainerWrap>
      </CropInfoSt.Wrap>
      {popupState.lotInfo ? <PopupLotInfo onClose={() => onTogglePopup('lotInfo')} /> : null}
    </>
  );
}

export default CropInfoBox;

export const ActiveAreaSt = {
  Wrap: styled.div`
    flex: 1; // 자유롭게 축소
    /* width: calc(100% - 485px - 304px); // 고정영역을 제외 */
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e9e7e7;
    transition: 0.4s;
    &.hide {
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
  `,
  PicArea: styled.div`
    width: 100%;
    max-height: calc(100% - 384px); // 최소 지도 영역 고정으로 처리
    padding: 10px 12px;
    box-sizing: border-box;
    border-bottom: 1px solid #e9e7e7;
    display: flex;
    flex-direction: column;
    .picWrap {
      flex-shrink: 1;
      overflow-y: hidden;
      width: 100%;
      /* height: 372px; */
      max-height: calc(340px + 6px + 6px); // 이미지 2개, gap, 스크롤바 영역
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 6px;
    }
  `,
  PicCard: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 470px;
    /* height: 180px; */
    height: 170px;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 12px;
    padding: 2px;
    box-sizing: border-box;
    border: 1px solid #e9e7e7;
    &.single {
      width: 100%;
      height: 100%;
      strong {
        width: auto;
      }
    }
    &.active {
      background: #00b074;
      > div {
        background: #d1f1e6;
      }
    }
    &.disabled {
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: calc(100% - 2px);
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 12px;
      }
    }
  `,
  PicCardInner: styled.div`
    background: #fff;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 9px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  `,
  PicBox: styled.div`
    position: relative;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #e9e7e7;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    &:not(:last-child) {
      margin-right: 2px;
    }
    button {
      position: absolute;
      right: 8px;
      bottom: 8px;
      z-index: 9;
    }
  `,
  PicTitle: styled.strong`
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 12px;
    width: calc(100% - 24px);
    height: 32px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.6);
    color: #00b074;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  MapArea: styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 12px 17px;
    box-sizing: border-box;
    #mapContainer2 {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 360px;
      background-color: #c3c3c3;
      border-radius: 8px;
      /* overflow: hidden; */
      > .ol-viewport {
        border-radius: 8px;
      }
      > .loadingPage {
        width: 100%;
        height: 100%;
        color: #9f9f9f;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  `,
};

// 선택된 항목 있을때 상세 이미지 & 지도 영역
function ActiveArea({ hide, cardData }) {
  const [popLarge, setPopLarge] = useRecoilState(popupLargeImgState);
  //   const [popLarge, setPopLarge] = useState(false); // 사진 크게보기 팝업
  const [selected, setSelected] = useState(null); //복수사진 선택된 항목
  //더미데이터
  const [imageData, setImageData] = useState([
    { id: 1, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    //  { id: 2, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    //  { id: 3, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    //  { id: 4, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    //  { id: 5, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
  ]);

  const [mapInstance, setMapInstance] = useState<OlCore>();
  const [isObjectReady, setIsObjectReady] = useState(false);

  // TODO: 지도 올리기 (OlCore2)
  useEffect(() => {
    const parantElement = document.getElementById('mapContainer2');
    if (!parantElement?.querySelector('.ol-viewport')) {
      setTimeout(() => {
        const core = new OlCore(); // 중요) OlCore2

        setMapInstance(core);
        setIsObjectReady(true);
      }, 700);
    }
  }, []);

  // 선택된 카드 데이터 -> 보여줄 이미지 리스트 데이터 생성
  useEffect(() => {
    // 1) imgCount 여부 (복수이미지를 가진 카드인가)
    const activeCard = [...cardData].filter(({ active }) => active); // 선택된 카드들만 필터
    setImageData([...activeCard]?.map((ele) => ({ ...ele, id: ele.farmId, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1Z.jpg' })));
  }, [cardData]);

  // 사진 선택 버튼 클릭
  const selectPic = (id) => {
    setSelected(id);

    setImageData((prev) => {
      return [...prev].map((ele) => (ele.id === id ? { ...ele, active: true, completion: true } : { ...ele, active: false, completion: false }));
    });
  };

  // 이미지 클릭 -> 크게보기 팝업 오픈
  const onClickImage = () => {
    setPopLarge({ open: true });
  };

  return (
    <>
      <ActiveAreaSt.Wrap className={hide ? 'hide' : undefined}>
        <ActiveAreaSt.PicArea>
          <div className="picWrap cScroll">
            {imageData.length === 1
              ? imageData.map((item) => {
                  return (
                    <ActiveAreaSt.PicCard key={item.id} className={'single'} onClick={onClickImage}>
                      <ActiveAreaSt.PicCardInner>
                        <ActiveAreaSt.PicBox>
                          <ActiveAreaSt.PicTitle>{item.titleW}</ActiveAreaSt.PicTitle>
                          <img src={item.imageW} alt={item.titleW + '_근경'} />
                        </ActiveAreaSt.PicBox>
                        <ActiveAreaSt.PicBox>
                          <ActiveAreaSt.PicTitle>{item.titleZ}</ActiveAreaSt.PicTitle>
                          <img src={item.imageZ} alt={item.titleZ + '_원경'} />
                        </ActiveAreaSt.PicBox>
                      </ActiveAreaSt.PicCardInner>
                    </ActiveAreaSt.PicCard>
                  );
                })
              : imageData.map((item) => {
                  return (
                    <ActiveAreaSt.PicCard key={item.id} className={item.active ? 'active' : selected ? 'disabled' : ''} onClick={onClickImage}>
                      <ActiveAreaSt.PicCardInner>
                        <ActiveAreaSt.PicBox>
                          <ActiveAreaSt.PicTitle>{item.titleW}</ActiveAreaSt.PicTitle>
                          <img src={item.imageW} alt={item.titleW + '_근경'} />
                        </ActiveAreaSt.PicBox>
                        <ActiveAreaSt.PicBox>
                          <ActiveAreaSt.PicTitle>{item.titleZ}</ActiveAreaSt.PicTitle>
                          <img src={item.imageZ} alt={item.titleZ + '_원경'} />
                          {/* 단일 필지 선택시, 복수 사진일때 버튼 */}
                          <Button
                            $buttonType={'medium'}
                            text={'사진 선택'}
                            $iconSize={24}
                            icon={IcoPic}
                            $iconSpacing={6}
                            $isCompletion={item.completion}
                            $iconCompletion={IcoPicCompletion}
                            onClick={(evt) => {
                              evt.stopPropagation();
                              selectPic(item?.id);
                            }}
                          />
                        </ActiveAreaSt.PicBox>
                      </ActiveAreaSt.PicCardInner>
                    </ActiveAreaSt.PicCard>
                  );
                })}
          </div>
        </ActiveAreaSt.PicArea>
        <ActiveAreaSt.MapArea>
          <div id={'mapContainer2'} className="mapContainer" style={{ position: 'relative' }}>
            {!isObjectReady && <div className="loadingPage">Map Loading...</div>}
            <RangeSlider mapInstance={mapInstance} position="right" />
          </div>
        </ActiveAreaSt.MapArea>
      </ActiveAreaSt.Wrap>

      {/* 사진 크게보기 팝업 -> <Container/> 컴포넌트 */}
      {/* {popLarge && <PopupLargeImage close={() => setPopLarge(false)} />} */}
    </>
  );
}

const DataEnterAreaSt = {
  Wrap: styled.div`
    /* flex: 1; */
    width: 304px;
    /* height: 100%; */
    border-left: 1px solid #e9e7e7;
    background-color: #ffffff;
    box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.08);
    transition: 0.4s;
    &.hide {
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
  `,
  TabBtnWrap: styled.div`
    display: flex;
    .tabBtn {
      width: 152px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #e9e7e7;
      box-sizing: border-box;
      background: #f3f5f4;
      color: #7e8888cc;
      font-weight: 600;
      &.active {
        position: relative;
        background-color: #fff;
        color: #001a21;
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          width: 100%;
          height: 3px;
          border-radius: 2px 2px 0px 0px;
          background: #00b074;
        }
      }
    }
  `,
  ReadGrdTypeWrap: styled.div`
    height: calc(100% - 54px); // tab 영역 제외
    display: flex;
    flex-direction: column;
    ul {
      li.radioItem {
        height: 48px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        /* &:not(:last-child) {
          border-bottom: 1px solid #e9e7e7;
        } */
        border-bottom: 1px solid #e9e7e7;
        .percent {
          color: #7e8888;
          &.active {
            color: #025143;
          }
        }
      }
    }
  `,
  FormWrap: styled.div`
    padding: 10px 16px 16px 42px;
    /* border-top: 1px solid #e9e7e7; */
    border-bottom: 1px solid #e9e7e7;
    background-color: #f0f5f3;
    height: 100%;
    height: calc(100% - 383px);
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      li.formItem {
        .labelInput {
          min-height: 36px;
          height: inherit;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label,
          label {
            color: #00b074;
            font-weight: 500;
          }
          .percent {
            color: #7e8888;
            &.active {
              color: #025143;
            }
          }
        }
        .value {
          margin-top: 4px;
          margin-bottom: 8px;
          height: 17px;
          text-align: right;
          color: #6f979c;
          font-weight: 500;
        }
      }
    }
  `,
  SaveWrap: styled.div`
    margin-top: auto;
    margin-bottom: 36px;
    width: 100%;
    padding: 14px 12px 0 12px;
    box-sizing: border-box;
    border-top: 2px solid #00b074;
    display: flex;
    flex-direction: column;
    .totalWrap {
      margin-bottom: 16.5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .titleWrap {
        display: flex;
        align-items: center;
        gap: 8px;
        .ico {
          display: block;
          width: 18px;
          height: 18px;
          background: url(${IcoTotal}) no-repeat center;
        }
        .title {
          color: #024751;
          font-weight: 500;
        }
      }
      .valueWrap {
        display: flex;
        align-items: center;
        gap: 8px;
        .value {
          color: #024751;
          font-size: 16px;
          font-weight: 600;
        }
        .unit {
          color: #7e8888;
        }
      }
    }
    .lastUpdate {
      line-height: 14px;
      color: #00b074;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 16px;
      text-align: right;
      text-decoration-line: underline;
    }
  `,
};

const defaultOptions = [
  {
    value: '1',
    text: '비경지',
    checked: false,
    percent: '',
  },
  {
    value: '2',
    text: '휴경지',
    checked: false,
    percent: '',
  },
  {
    value: '3',
    text: '시설',
    checked: false,
    percent: '',
  },
  {
    value: '4',
    text: '경작지',
    checked: false,
    percent: '', // 경작지 100은 작물의 합이 100이라는 의미로?
  },
];

const defaultCropOptions = [
  {
    value: 'crop-1',
    text: '고추',
    checked: false,
    percent: '',
    prevPercent: 90,
    currentPercent: 80,
  },
  {
    value: 'crop-2',
    text: '귀리',
    checked: false,
    percent: '',
    prevPercent: '',
    currentPercent: '',
  },
  {
    value: 'crop-3',
    text: '무',
    checked: false,
    percent: '',
    prevPercent: '',
    currentPercent: '',
  },
  {
    value: 'crop-4',
    text: '보리',
    checked: false,
    percent: '',
    prevPercent: '',
    currentPercent: '',
  },
];

// 선택된 항목 있을때 데이터 입력하는 영역
function DataEnterArea({ hide, cardData }) {
  const [checkboxType, setCheckboxType] = useState('radio');
  const [tabNum, setTabNum] = useState(1); // 점유율 | 잔여량

  const [radioValue, setRadioValue] = useState(''); // 필지 타입 판독하기
  const [options, setOptions] = useState<any[]>(defaultOptions); // 비경지,휴경지,시설,경작지 옵션
  const [cropOptions, setCropOptions] = useState<any[]>(defaultCropOptions); // 작물 옵션

  const [totalSum, setTotalSum] = useState(0); // 점유율 총합
  const [totalSum2, setTotalSum2] = useState(0); // 잔여량 총합

  //   const [form, setForm, onChange] = useOnChange({}); // [점유율] - 작물input
  const [form2, setForm2, onChange2] = useOnChange({}); // [잔여량] - 작물input

  // 선택된 카드 데이터 -> 1개일때 입력창 형태 / 2개이상일때 입력창 형태 변경
  useEffect(() => {
    if (cardData?.length > 0) {
      const activeCard = [...cardData].filter(({ active }) => active); // 선택된 카드들만 필터
      console.log(activeCard?.length);
      setOptions(defaultOptions);
      setCropOptions(defaultCropOptions);
      if (activeCard?.length === 1) {
        setCheckboxType('checkbox');
      }
      if (activeCard?.length > 1) {
        setCheckboxType('radio');
      }
    }
  }, [cardData]);

  // [점유율] 전체 총합 구하기
  useEffect(() => {
    let newArr = [...options.filter((ele) => ele.checked), ...cropOptions]; // 경작지 옵션 + 작물 옵션
    let sum = 0;
    newArr.map((ele) => (sum += Number(ele.percent)));
    setTotalSum(sum);
  }, [options, cropOptions]);

  // [잔여량] 전체 총합 구하기
  useEffect(() => {
    if (Object.keys(form2)) {
      let sum = 0;
      Object.entries(form2).map(([key, value]) => (sum += Number(value)));
      setTotalSum2(sum);
    }
  }, [form2]);

  // 점유율 탭 -> 경지 종류 선택 [1차분류]
  const onChangeCheck = (e) => {
    const { type, id, name, value, checked, text } = e.target;
    console.log(type, id, name);
    console.log(value, checked, text);

    // 라디오 버튼(한개만 checked)
    if (type === 'radio') {
      // [비경지|휴경지|시설|경작지] 옵션
      if (name === 'grdType') {
        setRadioValue(value);

        setOptions((prev) => {
          let newArr = [...prev].map((ele) => (ele.value == value ? { ...ele, checked, percent: ele.text === '경작지' ? 0 : 100 } : { ...ele, checked: false, percent: 0 }));
          return newArr;
        });
        // FIXME: 경작지 버튼 꺼질때, 작물 옵션 초기화 (percent 등)
        if (value !== '4') {
          setCropOptions(defaultCropOptions);
        }
      }
      // 작물 옵션
      if (name === 'cropCheck') {
        setCropOptions((prev) => {
          let newCrop = [...prev].map((ele) => (ele.value == value ? { ...ele, checked, percent: 100 } : { ...ele, checked: false, percent: 0 }));
          return newCrop;
        });
      }
    }
    // 체크박스 버튼
    if (type === 'checkbox') {
      setOptions((prev) => {
        let newArr = [...prev].map((ele) => (ele.value == value ? { ...ele, checked, percent: checked === false ? '' : ele.percent } : { ...ele }));
        return newArr;
      });
      // FIXME: 경작지 버튼 끌 때 작물 옵션 초기화 (percent 등)
      if (value === '4' && checked === false) {
        setCropOptions(defaultCropOptions);
      }
    }
  };

  const onChangePercent = (e, optionType) => {
    const { name, value } = e.target;
    console.log(optionType);
    console.log(name, value);
    if (optionType === 'grdType') {
      setOptions((prev) => {
        let newCrop = [...prev].map((ele) => (ele.text === name ? { ...ele, percent: value } : { ...ele }));
        return newCrop;
      });
    }
    if (optionType === 'cropType') {
      setCropOptions((prev) => {
        let newCrop = [...prev].map((ele) => (ele.text === name ? { ...ele, percent: value } : { ...ele }));
        return newCrop;
      });
    }
  };

  // 경작지 항목 체크 유무
  const isCropChecked = options.find((ele) => ele.text === '경작지').checked;

  //   console.log(form);
  console.log(options);
  console.log(cropOptions);

  return (
    <DataEnterAreaSt.Wrap className={hide ? 'hide' : undefined}>
      <DataEnterAreaSt.TabBtnWrap>
        <button className={`tabBtn ${tabNum === 1 && 'active'}`} onClick={() => setTabNum(1)}>
          점유율
        </button>
        <button className={`tabBtn ${tabNum === 2 && 'active'}`} onClick={() => setTabNum(2)}>
          잔여량
        </button>
      </DataEnterAreaSt.TabBtnWrap>
      <DataEnterAreaSt.ReadGrdTypeWrap>
        {tabNum === 1 && (
          <>
            <ul>
              {options.map((item, idx) => (
                <li key={`radio-${idx}`} className="radioItem">
                  <Checkbox
                    type={checkboxType}
                    id={`grdType-${item.value}`}
                    name="grdType" // 라디오끼리 같은 name
                    text={item.text}
                    value={item.value}
                    style={checkboxType === 'radio' ? { width: '100%', height: '100%' } : undefined}
                    checked={item.checked}
                    onChange={onChangeCheck}
                  />
                  {/* 라디오일때 */}
                  {checkboxType === 'radio' && item.text !== '경작지' && <p className={`percent ${item.checked && 'active'}`}>{item.percent || 0}%</p>}

                  {/* 입력일때 */}
                  {checkboxType !== 'radio' && item.text !== '경작지' && <Input width={102} height={36} unit="%" placeholder="입력" name={item.text} value={item.percent} onChange={(e) => onChangePercent(e, 'grdType')} disabled={!item.checked} />}
                </li>
              ))}
            </ul>

            {/* 경작지 일때 작물 input 목록 */}
            {isCropChecked && (
              <DataEnterAreaSt.FormWrap className="cScroll">
                <ul>
                  {cropOptions?.map((crop) => (
                    <li key={`cropCheck-${crop.value}`} className="formItem">
                      {/* 라디오 버튼 타입 */}
                      {checkboxType === 'radio' ? (
                        <div className="labelInput">
                          <Checkbox
                            type={'radio'}
                            id={`cropCheck-${crop.value}`}
                            name="cropCheck" // 라디오끼리 같은 name
                            text={crop.text}
                            value={crop.value}
                            style={{ width: '100%', height: '100%' }}
                            checked={crop.checked}
                            onChange={onChangeCheck}
                          />
                          <p className={`percent ${crop.checked && 'active'}`}>{crop?.percent || 0}%</p>
                        </div>
                      ) : (
                        <>
                          {/* 퍼센트 입력 타입 */}
                          <div className="labelInput">
                            <p className="label">{crop.text || ''}</p>
                            <Input width={102} height={36} unit="%" placeholder="입력" name={crop.text} value={crop.percent} onChange={(e) => onChangePercent(e, 'cropType')} />
                          </div>
                          {/* 당회차 / 전회차 값이 존재하면 표시 */}
                          {crop?.prevPercent || crop?.currentPercent ? (
                            <p className="value">
                              {crop?.prevPercent && `전회차: ${crop?.prevPercent}`} {crop?.prevPercent && `/ 당회차: ${crop?.currentPercent}`}
                            </p>
                          ) : null}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </DataEnterAreaSt.FormWrap>
            )}
            <DataEnterAreaSt.SaveWrap>
              <div className="totalWrap">
                <div className="titleWrap">
                  <span className="ico"></span>
                  <p className="title">전체 총합</p>
                </div>
                <div className="valueWrap">
                  <p className="value">{totalSum}</p>
                  <span className="unit">{'%'}</span>
                </div>
              </div>
              <p className="lastUpdate">{`최종변경 : ${'2024년 10월 1일, 08:00 AM'}`}</p>
              <Button width={280} text="저장" disabled={!radioValue} />
            </DataEnterAreaSt.SaveWrap>
          </>
        )}

        {tabNum === 2 && (
          <>
            <ul>
              <li className="radioItem">{'경작지'}</li>
            </ul>
            <DataEnterAreaSt.FormWrap>
              <ul>
                <li className="formItem">
                  <div className="labelInput">
                    <p className="label">고추</p>
                    <Input width={102} height={36} unit="%" placeholder="입력" name={'고추'} value={form2?.고추} onChange={onChange2} />
                  </div>
                </li>
                <li className="formItem">
                  <div className="labelInput">
                    <p className="label">귀리</p>
                    <Input width={102} height={36} unit="%" placeholder="입력" name={'귀리'} value={form2?.귀리} onChange={onChange2} />
                  </div>
                </li>
              </ul>
            </DataEnterAreaSt.FormWrap>
            <DataEnterAreaSt.SaveWrap>
              <div className="totalWrap">
                <div className="titleWrap">
                  <span className="ico"></span>
                  <p className="title">전체 총합</p>
                </div>
                <div className="valueWrap">
                  <p className="value">{totalSum2}</p>
                  <span className="unit">{'%'}</span>
                </div>
              </div>
              <p className="lastUpdate">최종변경 : 2024년 10월 1일, 08:00 AM</p>
              <Button width={280} text="저장" disabled={!radioValue} />
            </DataEnterAreaSt.SaveWrap>
          </>
        )}
      </DataEnterAreaSt.ReadGrdTypeWrap>
    </DataEnterAreaSt.Wrap>
  );
}
