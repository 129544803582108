import React from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import NoData from '../components/atoms/NoData';
import IcoLot from '../assets/images/noData/ico-lot.svg';

const Content = {
  Inner: styled.div`
    padding: 36px 0 0;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    > * {
      flex: 1;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
};

const PopupAddLot = ({ onClose }) => {
  return (
    <PopupTemplate
      title={'필지 주소 등록'}
      width={668}
      isDimmed={true}
      onClose={onClose}
      footer={<Button width={'full'} text={'추가하기'} />}
      content={
        <Content.Inner>
          <Content.Row>
            <Select option={[{ text: '1', value: 1, active: false }]} $borderColor={'#E5E5EC'} placeHolder={'시/도'} />
            <Select option={[{ text: '1', value: 1, active: false }]} $borderColor={'#E5E5EC'} placeHolder={'시/구/군'} />
            <Select option={[{ text: '1', value: 1, active: false }]} $borderColor={'#E5E5EC'} placeHolder={'동/면'} />
          </Content.Row>
          <NoData text={'필지 주소를 추가해주세요'} color={'#00B074'} fontSize={14} padding={[45, 74]} icon={IcoLot} $iconSize={36} />
        </Content.Inner>
      }
    />
  );
};

export default PopupAddLot;
