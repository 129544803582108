import React, { ReactNode, SetStateAction } from 'react';
import styled from 'styled-components';

export const TabContentStyle = {
  TabList: styled.ul`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  `,
  TabItem: styled.li`
    position: relative;
    flex: 1;
    padding-left: 5px;
    color: #3f3f3f;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    height: 35px;
    &:not(:last-child) {
      margin-right: 7px;
    }
    &:after {
      position: absolute;
      left: 0;
      top: calc(100% + 3px);
      content: '';
      width: 100%;
      height: 3px;
      border-radius: 1px;
      background: #dadada;
    }
    &.pass {
      cursor: pointer;
      &:after {
        background: #1db983;
      }
    }
    &.active {
      font-weight: 600;
      color: #001a21;
    }
  `,
  Content: styled.div`
    height: calc(100% - 93px);
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 rgba(16, 24, 40, 0.15);
  `,
  Inner: styled.div`
    height: calc(100% - 48px);
    padding: 24px 40px;
  `,
  ContBox: styled.div`
    height: 100%;
  `,
};

export const Section = styled.section`
  width: 1116px;
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #e5e5ec;
  }
  &:first-child {
    padding: 0 0 20px 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

export const NextStep = styled.div`
  margin-top: 20px;
  box-sizing: border-box;
  border-top: 2px solid rgba(49, 193, 110, 0.6);
  display: flex;
  flex-direction: row-reverse;
  padding-top: 12px;
`;

export interface TabContentProps {
  tabList: { name: string; pass: boolean; active: boolean }[];
  setTabList: React.Dispatch<SetStateAction<{ name: string; pass: boolean; active: boolean }[]>>;
  content: ReactNode;
}

const TabContent = ({ tabList, setTabList, content }: TabContentProps) => {
  //탭 클릭이벤트
  const onTabClick = (item) => {
    const newTabList = [...tabList];
    newTabList.map((i) => {
      i.active = item.name === i.name;
    });
    setTabList(newTabList);
  };
  return (
    <>
      <TabContentStyle.TabList>
        {tabList.map((item) => {
          return (
            <TabContentStyle.TabItem
              key={item.name}
              className={`${item.active ? 'active' : ''} ${item.pass ? 'pass' : ''}`}
              onClick={() => {
                if (item.pass) {
                  onTabClick(item);
                }
              }}
            >
              {item.name}
            </TabContentStyle.TabItem>
          );
        })}
      </TabContentStyle.TabList>
      <TabContentStyle.Content>
        <TabContentStyle.Inner>
          <TabContentStyle.ContBox>{content}</TabContentStyle.ContBox>
        </TabContentStyle.Inner>
      </TabContentStyle.Content>
    </>
  );
};

export default TabContent;
