import { useQuery } from '@tanstack/react-query';
import queryKeys from '../keys/queryKeys';
import { getRounds } from '../service/project';

// 사업의 회차 리스트 조회
export function useGetRounds(projectId) {
  return useQuery({
    queryKey: [queryKeys.projectKeys.all],
    queryFn: () => getRounds(projectId),
  });
}
