import Style from 'ol/style/Style';
import { FeatureLike } from 'ol/Feature';
import { Circle, Fill, Icon, Stroke } from 'ol/style.js';
import IcoPolygonImage from '../../assets/images/map/ico-polygon-image.svg';
import IcoPolygonImageA from '../../assets/images/map/ico-polygon-image-active.svg';
import { Text } from 'ol/style';
import { Point, MultiPoint } from 'ol/geom';

//선택 여부에 따른 사진 페어수 텍스트 위치 offset 조정
const interiorGeometry = (type: 'active' | 'default', feature, resolution) => {
  const interiorPoint = (feature.getGeometry() as any).getInteriorPoints();
  const point = new MultiPoint(interiorPoint.getCoordinates());
  point.translate(12 * resolution, (type === 'active' ? 22 : 12) * resolution);
  return point;
};

//기본 상태 테두리
const defaultStroke = (step) => {
  switch (step) {
    case 1:
      return 'rgba(49,193,110,0.6)';
    case 2:
      return 'rgba(51, 170, 255, 0.6)';
    case 3:
      return 'rgba(113, 81, 242, 0.60)';
    case 4:
      return 'rgba(255, 103, 30, 0.6)';
  }
};

//기본 상태 배경
const defaultFill = (step) => {
  switch (step) {
    case 1:
      return 'rgba(204,247,216,0.4)';
    case 2:
      return 'rgba(51, 170, 255, 0.4)';
    case 3:
      return 'rgba(113, 81, 242, 0.4)';
    case 4:
      return 'rgba(255, 140, 84, 0.4)';
  }
};

//활성 상태 테두리
const activeStroke = (step) => {
  switch (step) {
    case 1:
      return 'rgba(49,193,110,0.6)';
    case 2:
      return 'rgba(51, 170, 255, 0.6)';
    case 3:
      return '#7151F2';
    case 4:
      return 'rgba(255, 140, 84, 0.6)';
  }
};

//활성 상태 배경
const activeFill = (step) => {
  switch (step) {
    case 1:
      return 'rgba(49, 193, 110, 0.6)';
    case 2:
      return 'rgba(51, 170, 255, 0.6)';
    case 3:
      return 'rgba(113, 81, 242, 0.60)';
    case 4:
      return 'rgba(255, 140, 84, 0.6)';
  }
};

//필지 스타일
export const FARM_STYLE = {
  DEFAULT: (feature: FeatureLike, resolution: number) => {
    //폴리곤의 중심(extent의 중심이 아닌 텍스트를 표시하기 위한 별도의 포인트)
    const interiorPoint = (feature.getGeometry() as any).getInteriorPoints();
    //TODO  feature property에서 가져와 가공 =>  step:현재 상태(1=판독전 / 2=중간저장 / 3=검수완료)  / count:이미지 페어 수
    const step = 1;
    const count = 2;

    //기본 스타일
    const styles = [
      //테두리, 폴리곤 배경
      new Style({
        stroke: new Stroke({
          width: 6,
          color: defaultStroke(step),
        }),
        fill: new Fill({
          color: defaultFill(step),
        }),
      }),
    ];

    //이미지 페어가 있을 때 추가
    //아이콘
    const icon = new Style({
      image: new Icon({
        src: IcoPolygonImage,
        scale: 1,
        anchor: [14, 17],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
      }),
      geometry: function () {
        return new MultiPoint(interiorPoint.getCoordinates());
      },
    });
    //이미지 페어수
    const image = new Style({
      image: new Circle({
        radius: 10,
        fill: new Fill({
          color: count > 1 ? '#FF7600' : '#024751',
        }),
      }),
      text: new Text({
        text: String(count),
        font: '600 12px Pretendard',
        fill: new Fill({ color: '#fff' }),
      }),
      geometry: interiorGeometry('default', feature, resolution),
    });

    //이미지 페어가 하나라도 있으면 아이콘과 페어수 표시
    if (count > 0) {
      const newStyle = [...styles];
      newStyle.push(icon);
      newStyle.push(image);
      return newStyle;
    } else {
      return styles;
    }
  },
  ACTIVE: (feature: FeatureLike, resolution: number) => {
    //폴리곤의 중심(extent의 중심이 아닌 텍스트를 표시하기 위한 별도의 포인트)
    const interiorPoint = (feature.getGeometry() as any).getInteriorPoints();

    //TODO  feature property에서 가져와 가공 =>  step:현재 상태(1=판독전 / 2=중간저장 / 3=검수완료)  / count:이미지 페어 수
    const step = 1;
    const count = 1;

    //기본 스타일
    const styles = [
      //테두리, 폴리곤 배경
      new Style({
        stroke: new Stroke({
          width: 6,
          color: activeStroke(step),
        }),
        fill: new Fill({
          color: activeFill(step),
        }),
      }),

      //테두리2
      new Style({
        stroke: new Stroke({
          width: 2,
          color: '#fff',
        }),
      }),
    ];

    //이미지 페어가 있을 때 추가
    //아이콘
    const icon = new Style({
      image: new Icon({
        src: IcoPolygonImageA,
        scale: 1,
        anchor: [14, 26],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
      }),
      geometry: function () {
        return new Point(interiorPoint.getCoordinates()[0]);
      },
    });
    //이미지 페어수
    const image = new Style({
      image: new Circle({
        radius: 10,
        fill: new Fill({
          color: count > 1 ? '#FF7600' : '#024751',
        }),
      }),
      text: new Text({
        text: String(count),
        font: '600 12px Pretendard',
        fill: new Fill({ color: '#fff' }),
      }),
      geometry: interiorGeometry('active', feature, resolution),
    });

    //이미지 페어가 하나라도 있으면 아이콘과 페어수 표시
    if (count > 0) {
      const newStyle = [...styles];
      newStyle.push(icon);
      newStyle.push(image);
      return newStyle;
    } else {
      return styles;
    }
  },
};
