export const map = {
  modify: (layerName, featureId) => `/layers/${layerName}/${featureId}/geometry`,
};

export const address = '/data/LSCT_LAWDCD.csv';

export const image = {
  upload: '/upload',
};

export const project = {
  roundList: (projectId) => `/project/${projectId}`,
};

export const member = {
  projects: (memberId) => `/member/${memberId}/project`,
};

export const farmMap = {
  map: `/frammap/data`,
};
