import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IcoFilter from '../../../assets/images/cropInfoBox/ico-filter.svg';
import IcoReset from '../../../assets/images/cropInfoBox/ico-reset.svg';
import IcoFilterHover from '../../../assets/images/cropInfoBox/ico-filter-hover.svg';
import IcoFilterPopup from '../../../assets/images/cropInfoBox/ico-filter-popup.svg';
import Checkbox from '../../atoms/Checkbox';
import Button from '../../atoms/Button';
export const CropFilterStyle = {
  Wrap: styled.div`
    position: relative;
    z-index: 10;
    width: 180px;
    background: #fff;
  `,
  Head: styled.div`
    box-sizing: border-box;
    height: 50px;
    border-bottom: 1px solid #74e0bb;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
    padding: 9px 7px;
    display: flex;
    align-items: center;
  `,
  Button: styled.button`
    width: 91px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #001a21;
    font-size: 14px;
    font-weight: 500;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${IcoFilter}) no-repeat center;
      margin-right: 6px;
    }
    &:hover {
      background: #e5f8e8;
    }
    &:active {
      background: #ceefd4;
    }
    &.active {
      color: #fff;
      background: #00b074;
      border: 1px solid transparent;
      &:before {
        background-image: url(${IcoFilterHover});
      }
      &:hover {
        background: #008d5d;
      }
      &:active {
        background: #0e7d5f;
      }
    }
  `,
  Reset: styled.button`
    width: 32px;
    height: 32px;
    margin-left: 6px;
    box-sizing: border-box;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #001a21;
    font-size: 14px;
    font-weight: 500;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${IcoReset}) no-repeat center;
    }
    &:hover {
      background: #e5f8e8;
    }
    &:active {
      background: #ceefd4;
    }
  `,
  Cont: styled.div`
    height: calc(100% - 50px);
    padding: 0 10px;
  `,
  BadgeGroup: styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    &:not(:last-child) {
      border-bottom: 1px dashed #cfcfcf;
    }
  `,
  Badge: styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    border-radius: 50px;
    background: #ecfdf3;
    color: #027a48;
    font-size: 12px;
    font-weight: 500;
    padding: 0 8px 0 7px;
    margin: 4px;
    &:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #00b074;
      margin-right: 7px;
    }
  `,
  FilterContain: styled.div`
    position: relative;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
  `,
  Filter: styled.div`
    display: none;
    position: absolute;
    width: 308px;
    background: #fff;
    top: calc(100% + 4px);
    left: 0;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    &.active {
      display: block;
    }
  `,
  FilterHead: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    box-sizing: border-box;
    padding: 0 16px;
  `,
  FilterTitle: styled.strong`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #001a21;
    font-weight: 600;
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url(${IcoFilterPopup}) no-repeat center;
      margin-right: 8px;
    }
  `,
  FilterClose: styled.button`
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #f3f4f5;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 2px;
      height: 12px;
      background: #3f3f3f;
      content: '';
      display: block;
      border-radius: 5px;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `,
  FilterCont: styled.div`
    padding: 4px 16px 20px;
    display: flex;
    align-items: center;
  `,
  FilterCheckList: styled.div`
    flex: 1;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    height: 250px;
    padding: 12px 20px;
    &:not(:last-child) {
      margin-right: 4px;
    }
    ul {
      padding: 8px 0;
      &:not(:last-child) {
        border-bottom: 1px dashed #cfcfcf;
      }
      li {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  `,
  FilterFoot: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 16px;
  `,
  AllReset: styled.button`
    display: flex;
    align-items: center;
    color: #3f3f3f;
    font-size: 14px;
    font-weight: 500;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${IcoReset}) no-repeat center;
      margin-right: 6px;
    }
  `,
};

const CropFilter = ({ onChangeAll, onChangeField, formField, onChangeBefore, formBefore, onChangeCrop, formCrop }) => {
  const [popState, setPopState] = useState(false);
  const onTogglePopup = () => {
    setPopState(!popState);
  };
  return (
    <CropFilterStyle.Wrap>
      <CropFilterStyle.Head>
        <CropFilterStyle.FilterContain>
          <CropFilterStyle.Row>
            <CropFilterStyle.Button type={'button'} onClick={onTogglePopup} className={popState ? 'active' : undefined}>
              필터
            </CropFilterStyle.Button>
            <CropFilterStyle.Reset type={'button'}>
              <span className={'hidden'}>초기화</span>
            </CropFilterStyle.Reset>
          </CropFilterStyle.Row>
          <CropFilterStyle.Filter className={popState ? 'active' : undefined}>
            <CropFilterStyle.FilterHead>
              <CropFilterStyle.FilterTitle>필터</CropFilterStyle.FilterTitle>
              <CropFilterStyle.FilterClose type={'button'} onClick={onTogglePopup}>
                <span className={'hidden'}>닫기</span>
              </CropFilterStyle.FilterClose>
            </CropFilterStyle.FilterHead>
            <CropFilterStyle.FilterCont>
              <CropFilterStyle.FilterCheckList className={'cScroll'}>
                <ul>
                  <li>
                    <Checkbox text="전체" id="Allfield" name="Allfield" onChange={onChangeAll} checked={formField?.Allfield} />
                  </li>
                  <li>
                    <Checkbox text="논" id="field1" name="field1" onChange={onChangeField} checked={formField?.field1} />
                  </li>
                  <li>
                    <Checkbox text="밭" id="field2" name="field2" onChange={onChangeField} checked={formField?.field2} />
                  </li>
                  <li>
                    <Checkbox text="과수" id="field3" name="field3" onChange={onChangeField} checked={formField?.field3} />
                  </li>
                  <li>
                    <Checkbox text="시설" id="field4" name="field4" onChange={onChangeField} checked={formField?.field4} />
                  </li>
                </ul>
              </CropFilterStyle.FilterCheckList>
              <CropFilterStyle.FilterCheckList className={'cScroll'}>
                <ul>
                  <li>
                    <Checkbox text="미판독" id="미판독" name="미판독" onChange={onChangeBefore} checked={formBefore?.['미판독']} />
                  </li>
                </ul>
                <ul>
                  <li>
                    <Checkbox text="전체" id="crop전체" name="전체" onChange={onChangeAll} checked={formCrop?.['전체']} />
                  </li>
                  <li>
                    <Checkbox text="양파" id="crop양파" name="양파" onChange={onChangeCrop} checked={formCrop?.['양파']} />
                  </li>
                  <li>
                    <Checkbox text="마늘" id="crop마늘" name="마늘" onChange={onChangeCrop} checked={formCrop?.['마늘']} />
                  </li>
                  <li>
                    <Checkbox text="무" id="crop무" name="무" onChange={onChangeCrop} checked={formCrop?.['무']} />
                  </li>
                  <li>
                    <Checkbox text="당근" id="crop당근" name="당근" onChange={onChangeCrop} checked={formCrop?.['당근']} />
                  </li>
                  <li>
                    <Checkbox text="양배추" id="crop양배추" name="양배추" onChange={onChangeCrop} checked={formCrop?.['양배추']} />
                  </li>
                  <li>
                    <Checkbox text="기타" id="crop기타" name="기타" onChange={onChangeCrop} checked={formCrop?.['기타']} />
                  </li>
                </ul>
              </CropFilterStyle.FilterCheckList>
            </CropFilterStyle.FilterCont>
            <CropFilterStyle.FilterFoot>
              <CropFilterStyle.AllReset type={'button'}>전체 초기화</CropFilterStyle.AllReset>
              <Button text={'선택 적용(' + '2' + ')'} height={32} padding={12} fontSize={14} />
            </CropFilterStyle.FilterFoot>
          </CropFilterStyle.Filter>
        </CropFilterStyle.FilterContain>
      </CropFilterStyle.Head>
      <CropFilterStyle.Cont>
        {Object.values(formField).filter((i) => i).length ? (
          <CropFilterStyle.BadgeGroup>
            {Object.keys(formField).map((key) => {
              if (formField[key]) {
                return <CropFilterStyle.Badge key={key}>{key}</CropFilterStyle.Badge>;
              } else {
                return null;
              }
            })}
          </CropFilterStyle.BadgeGroup>
        ) : null}
        {Object.values(formCrop).filter((i) => i).length ? (
          <CropFilterStyle.BadgeGroup>
            {Object.keys(formCrop).map((key) => {
              if (formCrop[key]) {
                return <CropFilterStyle.Badge key={key}>{key}</CropFilterStyle.Badge>;
              } else {
                return null;
              }
            })}
          </CropFilterStyle.BadgeGroup>
        ) : null}
        {Object.values(formBefore).filter((i) => i).length ? (
          <CropFilterStyle.BadgeGroup>
            {Object.keys(formBefore).map((key) => {
              if (formBefore[key]) {
                return <CropFilterStyle.Badge key={key}>{key}</CropFilterStyle.Badge>;
              } else {
                return null;
              }
            })}
          </CropFilterStyle.BadgeGroup>
        ) : null}
      </CropFilterStyle.Cont>
    </CropFilterStyle.Wrap>
  );
};

export default CropFilter;
