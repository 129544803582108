import React, { ReactNode } from 'react';
import { atom } from 'recoil';
import { PopupProps } from '../popup/PopupTemplate';
import { ConfirmProps } from '../components/templates/Confirm';
import { UserInfoProps } from './_interface';
import { recoilPersist } from 'recoil-persist';

// 새로운 저장소 객체 생성
export function newPersistAtom(key: string = 'sessionStorage') {
  // 스토리지에 저장하는 기능
  const { persistAtom } = recoilPersist({
    key: key,
    storage: sessionStorage || localStorage || undefined, // localStorage 또는 sessionStorage
  });
  return persistAtom;
}

// 로그인 후 유저 정보
export const userInfoState = atom<UserInfoProps>({
  key: 'userData',
  default: {},
  effects_UNSTABLE: [newPersistAtom('userInfo')], // persist 적용
});

export const popupState = atom<PopupProps>({
  key: 'popupState',
  default: {
    isDimmed: true,
    title: '',
    width: 600,
    content: <></>,
    footer: undefined,
  },
});

export const confirmState = atom<ConfirmProps>({
  key: 'confirmState',
  default: {
    width: 280,
    message: '',
    leftText: '',
    rightText: '',
    leftFunc: () => {},
    rightFunc: () => {},
    show: false,
  },
});

export const loadingState = atom<{ show: boolean }>({
  key: 'loadingState',
  default: {
    show: false,
  },
});

export const popupLargeImgState = atom({
  key: 'popupLargeImg',
  default: {
    open: false,
  },
});

export const formState = atom<any>({
  key: 'formState',
  default: {},
});
