import React from 'react';
import styled, { CSSProperties } from 'styled-components';

export const ButtonStyle = {
  Wrap: styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${(p) => (p.$buttonType === 'small' ? '600' : '500')};
    box-sizing: border-box;
    border: 1px solid ${(p) => (p.$buttonType === 'small' ? '#b6e2bf' : p.$buttonType === 'medium' ? '#00B074' : p.$colorType === 'white' ? '#D9D9D9' : p.$colorType === 'redLine' ? '#EB8377' : 'transparent')};
    color: ${(p) =>
      p.$buttonType === 'small' || p.$buttonType === 'medium'
        ? '#00b074'
        : p.$buttonType === 'large'
          ? '#fff'
          : p.$colorType === 'orange' || p.$colorType === 'black'
            ? '#fff'
            : p.$colorType === 'white'
              ? '#626262'
              : p.$colorType === 'redLine'
                ? '#DC1600'
                : '#09D585'};
    width: ${(p) => (p.width ? (p.width === 'full' ? '100%' : p.width + 'px') : 'auto')};
    height: ${(p) => (p.height ? p.height + 'px' : p.$buttonType === 'small' ? '24px' : p.$buttonType === 'medium' ? '36px' : '48px')};
    padding: ${(p) => (p.padding ? `0 ${p.padding}px` : p.$buttonType === 'small' ? '0 6px' : p.$buttonType === 'medium' ? '0 12px' : '0 20px')};
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : p.$buttonType === 'small' ? '11px' : p.$buttonType === 'medium' ? '14px' : '16px')};
    border-radius: ${(p) => (p.radius ? p.radius + 'px' : p.$buttonType === 'small' ? '2px' : '4px')};
    background: ${(p) =>
      p.$buttonType === 'small' || p.$buttonType === 'medium'
        ? '#fff'
        : p.$buttonType === 'large'
          ? '#00b074'
          : p.$colorType === 'orange'
            ? '#EB5846'
            : p.$colorType === 'black'
              ? '#444'
              : p.$colorType === 'white' || p.$colorType === 'redLine'
                ? '#fff'
                : '#024751'};
    box-shadow: ${(p) => (p.$buttonType === 'large' ? '0px 0px 6px 0px rgba(0, 0, 0, 0.2)' : 'none')};
    &:hover {
      background: ${(p) =>
        p.$buttonType === 'small' || p.$buttonType === 'medium'
          ? '#e5f8e8'
          : p.$buttonType === 'large'
            ? '#008D5D'
            : p.$colorType === 'orange'
              ? '#FF8C54'
              : p.$colorType === 'black'
                ? '#666'
                : p.$colorType === 'white'
                  ? '#E9E7E7'
                  : p.$colorType === 'redLine'
                    ? '#ffe8e5'
                    : '#025C58'};
    }
    &:active {
      background: ${(p) =>
        p.$buttonType === 'small' || p.$buttonType === 'medium'
          ? '#ceefd4'
          : p.$buttonType === 'large'
            ? '#0E7D5F'
            : p.$colorType === 'orange'
              ? '#FF7600'
              : p.$colorType === 'black'
                ? '#555'
                : p.$colorType === 'white'
                  ? '#ddd'
                  : p.$colorType === 'redLine'
                    ? '#ffcac4'
                    : '#043A42'};
    }

    &:disabled {
      border: 1px solid ${(p) => (p.$buttonType === 'small' || p.$buttonType === 'medium' ? '#9a9a9a' : 'transparent')};
      background: #d9d9d9;
      color: #9a9a9a;
      cursor: default;
      ${(p) =>
        p.$iconDisabled &&
        `
        &:${p.$icoPosition === 'left' ? 'before' : 'after'} {
          background: url(${(p) => p.$iconDisabled && p.$iconDisabled}) no-repeat center;
        }
    `}
    }
    &.completion {
      border-color: #00b074;
      background: #00b074;
      color: #fff;
      ${(p) =>
        p.$iconCompletion &&
        `
       &:${p.$icoPosition === 'left' ? 'before' : 'after'} {
        background: url(${p.$iconCompletion}) no-repeat center;
      }
  `}
    }
    ${(p) =>
      p.icon &&
      `
         &:${p.$icoPosition === 'left' ? 'before' : 'after'} {
          content: '';
          width: ${p.$iconSize + 'px'};
          height: ${p.$iconSize + 'px'};
          margin-${p.$icoPosition === 'left' ? 'right' : 'left'}: ${p.$iconSpacing + 'px'};
          background: url(${p.icon}) no-repeat center;
        }
    `}
  `,
};

export interface ButtonProps {
  $buttonType?: 'small' | 'medium' | 'large';
  $colorType?: 'orange' | 'black' | 'white' | 'redLine';
  $icoPosition?: 'left' | 'right';
  width?: 'full' | number;
  height?: number;
  padding?: number;
  icon?: string;
  $iconHover?: string;
  $iconDisabled?: string;
  $isCompletion?: boolean;
  $iconCompletion?: string;
  $iconSize?: number;
  $iconSpacing?: number;
  fontSize?: number;
  radius?: number;
  onClick?: (e) => void;
  disabled?: boolean;
  text?: string;
  style?: CSSProperties;
}

const Button = ({ width, height, padding, icon, $iconHover, $iconDisabled, $isCompletion, $iconCompletion, $iconSize, $iconSpacing, fontSize, radius, onClick, disabled, text, $buttonType, $colorType, style, $icoPosition = 'left' }: ButtonProps) => {
  return (
    <ButtonStyle.Wrap
      className={$isCompletion ? 'completion' : undefined}
      width={width}
      height={height}
      padding={padding}
      icon={icon}
      $iconHover={$iconHover}
      $iconDisabled={$iconDisabled}
      $iconCompletion={$iconCompletion}
      $iconSize={$iconSize}
      $iconSpacing={$iconSpacing}
      fontSize={fontSize}
      radius={radius}
      onClick={onClick}
      disabled={disabled}
      $buttonType={$buttonType}
      $colorType={$colorType}
      $icoPosition={$icoPosition}
      style={style}
    >
      {text}
    </ButtonStyle.Wrap>
  );
};

export default Button;
