import * as map from './map';
import * as address from './address';
import * as image from './image';
import * as project from './project';
import * as member from './member';
import * as farmMap from './farmMap';

const query = {
  map,
  address,
  image,
  project,
  member,
  farmMap,
};
export default query;
