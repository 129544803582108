import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import LabelInput, { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import LabelSelect from '../../../../components/atoms/LabelSelect';
import HelpMessage from '../../../../components/atoms/HelpMessage';
import IcoHelp from '../../../../assets/images/etc/ico-help-message.svg';
import Button from '../../../../components/atoms/Button';
import IcoFileUpload from '../../../../assets/images/button/ico-file-upload.svg';
import ScrollTable from '../../../../components/atoms/ScrollTable';
import Checkbox from '../../../../components/atoms/Checkbox';
import NoData from '../../../../components/atoms/NoData';
import IcoNoLot from '../../../../assets/images/noData/ico-no-lot.svg';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';
import { useRecoilState } from 'recoil';
import { formState } from '../../../../store/Atoms';
import { PagingTableStyle } from '../../../../components/atoms/PagingTable';
import TdEllipsis from '../../../../components/atoms/TdEllipsis';
import RangePicker from '../../../../components/atoms/RangePicker';
import Input from '../../../../components/atoms/Input';
import LabelValue, { LabelValueStyle } from '../../../../components/atoms/LabelValue';

const Step1 = ({ isEditMode, onToggleEditMode, onTogglePopup }) => {
  const [form, setForm] = useRecoilState(formState);

  return (
    <div style={{ height: '100%', width: 1130 }}>
      {isEditMode ? (
        <>
          <div className={'cScroll'} style={{ height: 'calc(100% - 102px)' }}>
            <Section>
              <LabelInput
                label={'발주처(기관명)'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'발주처명을 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value });
                }}
                value={form?.name || ''}
              />
            </Section>
            <Section>
              <LabelInput
                label={'기관 유형'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'기관 유형을 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, type: e.target.value });
                }}
                value={form?.type || ''}
              />
            </Section>
            <Section>
              <LabelInputStyle.Wrap $alignItem={'flex-start'}>
                <LabelInputStyle.Key required={true} $alignItem={'flex-start'}>
                  주소
                </LabelInputStyle.Key>
                <div>
                  <LabelInputStyle.Wrap style={{ marginBottom: 6 }}>
                    <Input readOnly={true} value={form?.zoneCode || ''} width={160} height={48} placeholder={'우편번호'} />
                    <Button $buttonType={'small'} text={'주소찾기'} onClick={onTogglePopup} height={48} fontSize={14} padding={20} style={{ marginLeft: 6 }} />
                  </LabelInputStyle.Wrap>
                  <LabelInputStyle.Wrap style={{ marginBottom: 6 }}>
                    <Input readOnly={true} value={form?.address || ''} width={532} height={48} placeholder={'도로명 또는 지번'} />
                  </LabelInputStyle.Wrap>
                  <LabelInputStyle.Wrap>
                    <Input
                      width={532}
                      height={48}
                      placeholder={'상세 주소'}
                      onChange={(e) => {
                        setForm({ ...form, detailAddress: e.target.value });
                      }}
                      value={form?.detailAddress || ''}
                    />
                  </LabelInputStyle.Wrap>
                </div>
              </LabelInputStyle.Wrap>
            </Section>
            <Section>
              <LabelInput
                label={'행정구역'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'행정구역을 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, area: e.target.value });
                }}
                value={form?.area || ''}
              />
            </Section>
            <Section>
              <LabelInput
                label={'전화번호'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'전화번호를 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, phone: e.target.value });
                }}
                value={form?.phone || ''}
              />
            </Section>
            <Section>
              <LabelInput
                label={'이메일'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'이메일을 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value });
                }}
                value={form?.email || ''}
              />
            </Section>
            <Section>
              <LabelInput
                label={'담당부서'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'담당부서를 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, department: e.target.value });
                }}
                value={form?.department || ''}
              />
            </Section>
          </div>
          <Section>
            <NextStep>
              <Button $buttonType={'large'} text={'다음 단계'} $icoPosition={'right'} icon={IcoStepNext} $iconSize={16} $iconSpacing={6} style={{ marginRight: 6 }} />
            </NextStep>
          </Section>
        </>
      ) : (
        <>
          <Section>
            <LabelValue label={'발주처(기관명)'} value={form?.name || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValue label={'기관 유형'} value={form?.type || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValueStyle.Wrap $alignItem={'flex-start'}>
              <LabelValueStyle.Key $alignItem={'flex-start'} $labelWidth={155} fontSize={16} $labelColor={'#001A21'}>
                주소
              </LabelValueStyle.Key>
              <LabelValueStyle.Cont>
                <LabelValueStyle.Row>
                  <LabelValueStyle.Badge>우편번호</LabelValueStyle.Badge>
                  <LabelValueStyle.Value fontSize={16} $valueColor={'#001A21'}>
                    ({form?.zoneCode || ''})
                  </LabelValueStyle.Value>
                </LabelValueStyle.Row>
                <LabelValueStyle.Row>
                  <LabelValueStyle.Badge>주소</LabelValueStyle.Badge>
                  <LabelValueStyle.Value fontSize={16} $valueColor={'#001A21'}>
                    {form?.address || ''}
                  </LabelValueStyle.Value>
                </LabelValueStyle.Row>
                <LabelValueStyle.Row>
                  <LabelValueStyle.Badge>상세주소</LabelValueStyle.Badge>
                  <LabelValueStyle.Value fontSize={16} $valueColor={'#001A21'}>
                    {form?.detailAddress || ''}
                  </LabelValueStyle.Value>
                </LabelValueStyle.Row>
              </LabelValueStyle.Cont>
            </LabelValueStyle.Wrap>
          </Section>
          <Section>
            <LabelValue label={'행정구역'} value={form?.area || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValue label={'전화번호'} value={form?.phone || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValue label={'이메일'} value={form?.email || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValue label={'담당부서'} value={form?.department || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Button $buttonType={'large'} text={'수정하기'} style={{ marginTop: 20 }} onClick={onToggleEditMode} />
        </>
      )}
    </div>
  );
};

export default Step1;
