import React, { useState } from 'react';
import IcoPage2 from '../../../assets/images/nav/ico-menu2-page.svg';
import IcoNoProject from '../../../assets/images/noData/ico-no-project.svg';
import PagingTable from '../../../components/atoms/PagingTable';
import AdminContainer from '../../../components/templates/Container/AdminContainer';
import Checkbox from '../../../components/atoms/Checkbox';
import RoundBadge from '../../../components/atoms/RoundBadge';
import TdEllipsis from '../../../components/atoms/TdEllipsis';
import NoData from '../../../components/atoms/NoData';
import { useNavigate } from 'react-router-dom';

const ClientList = () => {
  const navigate = useNavigate();
  const [cur, setCur] = useState(1);

  const [dummy, setDummy] = useState([
    { id: 1, name: '제주도청', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥', checked: false },
    { id: 2, name: '경상남도청', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥', checked: false },
    { id: 3, name: '독도청', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥', checked: false },
    { id: 4, name: '수협', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥', checked: false },
    { id: 5, name: '농협', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥', checked: false },
    { id: 6, name: '대한축구협회', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥', checked: false },
    { id: 7, name: '대한축구협회', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥', checked: false },
    { id: 8, name: '대한축구협회', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥', checked: false },
    { id: 9, name: '대한축구협회', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥', checked: false },
    { id: 10, name: '대한축구협회', address: '제주 서귀포시 호근서호로 20-14', phone: '010-1234-5678', email: 'movements@movements.kr', department: '농촌진흥과', checked: false },
  ]);

  return (
    <AdminContainer
      title={'발주처 관리'}
      icon={IcoPage2}
      headRight={
        <button type={'button'} style={{ color: '#00B074', fontSize: 16, fontWeight: 500 }} onClick={() => navigate('/client/add')}>
          + 발주처 추가
        </button>
      }
      content={
        <PagingTable
          totalPages={100}
          currentPage={cur}
          setCurrentPage={setCur}
          caption={'발주처 목록'}
          colgroup={[60, 300, 'auto', 180, 300, 280]}
          hasTotal={true}
          thead={
            <tr>
              <th>
                <Checkbox id={'checkAll'} onChange={() => {}} />
              </th>
              <th>발주처(기관)</th>
              <th>주소</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>담당부서</th>
            </tr>
          }
          tbody={
            <>
              {dummy.length ? (
                dummy.map((item: any) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Checkbox id={'chk_' + item.id} checked={item.checked} onChange={() => {}} />
                      </td>
                      <td onClick={() => navigate('/client/detail')} style={{ cursor: 'pointer' }}>
                        <TdEllipsis str={item.name} />
                      </td>
                      <td onClick={() => navigate('/client/detail')} style={{ cursor: 'pointer' }}>
                        <TdEllipsis str={item.address} />
                      </td>
                      <td onClick={() => navigate('/client/detail')} style={{ cursor: 'pointer' }}>
                        {item.phone}
                      </td>
                      <td onClick={() => navigate('/client/detail')} style={{ cursor: 'pointer' }}>
                        <TdEllipsis str={item.email} />
                      </td>
                      <td onClick={() => navigate('/client/detail')} style={{ cursor: 'pointer' }}>
                        <TdEllipsis str={item.department} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7} rowSpan={10} style={{ height: 600 }}>
                    <NoData text={'등록된 발주처가 없습니다.'} icon={IcoNoProject} color={'#3F3F3F'} />
                  </td>
                </tr>
              )}
            </>
          }
        />
      }
    />
  );
};

export default ClientList;
