import React, { useState } from 'react';
import { NextStep, Section } from 'components/atoms/TabContent';
import Button from 'components/atoms/Button';
import IcoStepDone from 'assets/images/button/ico-step-done.svg';
import HelpMessage from 'components/atoms/HelpMessage';
import IcoHelp from 'assets/images/etc/ico-help-message.svg';
import IcoFileUpload from 'assets/images/button/ico-file-upload.svg';
import styled from 'styled-components';
import FileButton from 'components/atoms/FileButton';

const Step4Style = {
  HelpWrap: styled.div`
    height: 21px;
    display: flex;
    align-items: center;
    gap: 12px;
    .downBtn {
      color: #00b074;
      text-decoration: underline;
      line-height: 20px;
    }
  `,
  BoxWrap: styled.div`
    width: 100%;
    height: calc(100% - 36px - 16px - 62px - 20px); // helpMsg, saveBtn
    background-color: #f6f6f6;
    border: 1px solid #e7eaee;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    > .box {
      flex-grow: 0;
      width: 380px;
      height: 100%;
      background-color: #fff;
      border-left: 1px solid #e7eaee;
      border-right: 1px solid #e7eaee;
      box-sizing: border-box;
      &.active {
        border-radius: 8px 0 0 8px;
        border: 1px solid #00b074;
      }
    }
  `,
};

const Step4 = () => {
  const [csvFile, setCsvFile] = useState<any>(null);
  console.log(csvFile);
  return (
    <>
      <Section>
        <HelpMessage
          message={'혼작 시 대표 작물의 지정 기준을 설정하세요.'}
          style={{
            color: '#001A21',
            fontSize: '16px',
            fontWeight: '600',
          }}
        />
      </Section>
      <Section style={{ height: 'calc(100% - 64px)' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Step4Style.HelpWrap>
            <HelpMessage icon={IcoHelp} message={'지정 기준을 정해진 양식의 CSV로 작성해 일괄 추가할 수 있습니다'} style={{}} />
            <button className="downBtn">혼작 지정 기준 csv 양식 다운로드</button>
          </Step4Style.HelpWrap>

          <FileButton
            id={'cropCsvTempOpen'}
            text={'CSV 불러오기'}
            onChange={(e: any) => {
              if (e.target.files?.length) {
                setCsvFile((prev) => ({ ...prev, template: e.target.files[0] }));
              }
            }}
            $isShowFileName={false}
            fileName={csvFile?.template?.name || ''}
          />
          {/* <Button $buttonType={'medium'} text={'CSV 불러오기'} icon={IcoFileUpload} $iconSize={20} $iconSpacing={6} style={{ marginRight: 6 }} /> */}
        </div>

        <Step4Style.BoxWrap>
          <div className="box active"></div>
          <div className="box"></div>
          <div className="box"></div>
        </Step4Style.BoxWrap>

        <NextStep>
          <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} style={{ marginRight: 6 }} />
        </NextStep>
      </Section>
    </>
  );
};

export default Step4;
