import React, { useEffect, useState } from 'react';
import AdminSubContainer from '../../../components/templates/Container/AdminSubContainer';
import TabContent, { NextStep, Section } from '../../../components/atoms/TabContent';
import LabelInput, { LabelInputStyle } from '../../../components/atoms/LabelInput';
import LabelSelect from '../../../components/atoms/LabelSelect';
import HelpMessage from '../../../components/atoms/HelpMessage';
import IcoHelp from '../../../assets/images/etc/ico-help-message.svg';
import IcoFileUpload from '../../../assets/images/button/ico-file-upload.svg';
import IcoStepNext from '../../../assets/images/button/ico-step-next.svg';
import IcoNoLot from '../../../assets/images/noData/ico-no-lot.svg';
import ScrollTable from '../../../components/atoms/ScrollTable';
import Checkbox from '../../../components/atoms/Checkbox';
import Button from '../../../components/atoms/Button';
import NoData from '../../../components/atoms/NoData';
import PopupAddLot from '../../../popup/PopupAddLot';
import { useRecoilState } from 'recoil';
import { formState } from '../../../store/Atoms';
import { useNavigate } from 'react-router-dom';
import Step1 from './AddStep/Step1';
import Step3 from './AddStep/Step3';
import Step2 from './AddStep/Step2';
import { DateObject } from 'react-multi-date-picker';
import Step4 from './AddStep/Step4';

const initForm = {
  projectName: '',
  projectId: '',
  client: '',
  lotList: [],
  round: null,
  template: null,
  reader: [],
  inspector: [],
  date: {
    from: new DateObject(),
    to: new DateObject(),
  },
};

const WorkPlaceAdd = () => {
  const navigate = useNavigate();
  const [form, setForm] = useRecoilState(formState);

  const [tabList, setTabList] = useState([
    { name: '1. 기본 정보', pass: true, active: true },
    { name: '2. 작물 정보', pass: false, active: false },
    { name: '3. 판독 정보', pass: false, active: false },
    { name: '4. 혼작 정보', pass: false, active: false },
  ]);
  console.log('rerender??', tabList);

  //팝업 on/off 관리
  const [popupState, setPopupState] = useState({
    lotAdd: false,
  });

  //팝업 토글
  const onTogglePopup = (key) => {
    setPopupState({ ...popupState, [key]: !popupState[key] });
  };

  //다음 단계(idx는 현재스텝의 다음 index값)
  const onNextStep = (idx) => {
    const newArr = [...tabList];
    newArr.map((i, index) => {
      i.pass = index <= idx;
      i.active = index === idx;
    });
    setTabList(newArr);
  };

  //form 객체 세팅(recoil 저장)
  useEffect(() => {
    setForm(initForm);
  }, []);

  return (
    <AdminSubContainer
      title={'사업장 관리'}
      onNavigate={() => navigate('/workPlace')}
      content={
        <>
          <TabContent
            tabList={tabList}
            setTabList={setTabList}
            content={
              <>
                {/* 탭별 on/off*/}
                {tabList[0].active ? (
                  <>
                    <Step1 onTogglePopup={() => onTogglePopup('lotAdd')} onNextStep={onNextStep} />
                    {popupState.lotAdd ? <PopupAddLot onClose={() => onTogglePopup('lotAdd')} /> : null}
                  </>
                ) : null}
                {tabList[1].active ? <Step2 onNextStep={onNextStep} /> : null}
                {tabList[2].active ? <Step3 onNextStep={onNextStep} /> : null}
                {tabList[3].active ? <Step4 /> : null}
              </>
            }
          />
        </>
      }
    />
  );
};

export default WorkPlaceAdd;
