import React, { useEffect, useRef, useState } from 'react';
import { bjdCode } from 'data/bjdCode';

import styled from 'styled-components';
import IcoArrow from 'assets/images/map/ico-arrow-adre.svg';
import { postBjdCd } from 'service/farm';
import { getCoordFromJuso } from 'service/geoCoder';
import Button from 'components/atoms/Button';
import { useOutsideClick } from 'util/useOutsideClick';

const SelectAdreSt = {
  Wrap: styled.div`
    position: fixed;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  // 현재 지도 중심으로 표시 고정
  RegionWrap: styled.div`
    display: flex;
    min-width: 289px;
    height: 48px;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 8px;
    padding: 0 24px;
    box-sizing: border-box;
    border: 2px solid #024751;
    border-radius: 26px;
    background: #fff;
    button {
      color: #001a21;
      font-weight: 600;
    }
    &.active {
      border-color: #00b074;
      button {
        color: #7d8888;
        &.active {
          color: #00b074;
        }
      }
    }
    .arrow {
      width: 20px;
      height: 20px;
      background: url(${IcoArrow}) no-repeat center;
    }
  `,
  ListWrap: styled.div`
    width: calc(112 * 3px);
    overflow: auto;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
  `,
  List: styled.ul`
    width: 100%;
    max-height: 430px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: none;
    box-sizing: border-box;
  `,
  Item: styled.li`
    min-width: 33.3%;
    height: 40px;
    font-family: 'Pretendard';
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
    &:nth-child(3n) {
      border-right: none;
    }
    &:hover {
      background-color: #f0f5f3;
    }
  `,
  MoveToBtn: styled.div`
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
    border-radius: 0 0 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    box-sizing: border-box;
    button {
      width: 100%;
      height: 40px;
      /* color: #fff; */
      /* background-color: #31c331; */
      &:disabled {
        cursor: default;
        /* background-color: #b4b4b4; */
      }
    }
  `,
};

const defaultForm = {
  sido: { name: '', bjcd: '' },
  signgu: { name: '', bjcd: '' },
  emd: { name: '', bjcd: '' },
  li: { name: '', bjcd: '' },
};

function SelectAddress({ clickMoveTo, centerLevels }) {
  const selectAdreRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);

  const [sidoOption, setSidoOption] = useState<any[]>([]);
  const [signguOption, setSignguOption] = useState<any[]>([]);
  const [emdOption, setEmdOption] = useState<any[]>([]);
  const [liOption, setLiOption] = useState<any[]>([]);
  const [liData, setLiData] = useState({ name: '', bjcd: '' });

  const [form, setForm] = useState<any>(defaultForm);

  // 지도가 움직여서 주소가 변경되면,..
  useEffect(() => {
    if (centerLevels) {
      setShowFilter(false); // 주소 선택창 닫기
      const zoom = centerLevels.zoom;
      const structure = centerLevels.structure; // 브이월드 API 주소 ('리' 한글 항목은 없음)
      const fullCode = structure.level4LC;
      const sidoCd = fullCode.slice(0, 2);
      const signguCd = fullCode.slice(0, 5);
      const emdCd = fullCode.slice(0, 8);
      const liCd = fullCode.slice(0, 10); // '리'code 있음

      console.log(structure);
      // name : 다른 API라서, 목록(팜맵)과 보여지는(브이월드) 이름이 일치하지 않을 수도 있는 문제
      if (zoom < 8) {
        setForm(defaultForm);
      }
      if (8 <= zoom && zoom < 12) {
        setForm({
          sido: { name: structure.level1, bjcd: sidoCd },
          signgu: { name: '', bjcd: '' },
          emd: { name: '', bjcd: '' },
          li: { name: '', bjcd: '' },
        });
      }
      if (12 <= zoom && zoom < 14) {
        setForm({
          sido: { name: structure.level1, bjcd: sidoCd },
          signgu: { name: structure.level2, bjcd: signguCd },
          emd: { name: '', bjcd: '' },
          li: { name: '', bjcd: '' },
        });
      }
      if (14 <= zoom && zoom < 16) {
        setForm((prev) => ({
          sido: { name: structure.level1, bjcd: sidoCd },
          signgu: { name: structure.level2, bjcd: signguCd },
          emd: { name: structure.level4L, bjcd: emdCd },
          li: { name: '', bjcd: '' },
        }));
      }
      if (16 <= zoom) {
        setForm((prev) => ({
          sido: { name: structure.level1, bjcd: sidoCd },
          signgu: { name: structure.level2, bjcd: signguCd },
          emd: { name: structure.level4L, bjcd: emdCd },
          li: liData.bjcd === liCd ? { ...liData } : { name: '', bjcd: '' }, //직접 리를 선택한 경우 저장
        }));
      }
    }
  }, [centerLevels, liData]);

  // 팜맵 API로 법정동 주소 조회하기
  // 시도 리스트 조회 (최초 한번)
  useEffect(() => {
    const fetchBjdCode = async () => {
      const sidoList = await postBjdCd('SIDO');
      setSidoOption(sidoList);
    };
    fetchBjdCode();
  }, []);

  // 시도 선택되면 시군구 리스트 조회
  useEffect(() => {
    const fetchBjdCode = async () => {
      const signguList = await postBjdCd('SIGNGU', form.sido.bjcd);
      setSignguOption(signguList);
    };
    fetchBjdCode();
  }, [form.sido.bjcd]);

  // 시군구 선택되면 읍면동 리스트 조회
  useEffect(() => {
    const fetchBjdCode = async () => {
      const emdList = await postBjdCd('EMD', form.signgu.bjcd);
      setEmdOption(emdList);
    };
    fetchBjdCode();
  }, [form.signgu.bjcd]);

  // 읍면동 선택되면 '리' 리스트 조회
  useEffect(() => {
    const fetchBjdCode = async () => {
      const liList = await postBjdCd('LI', form.emd.bjcd);
      setLiOption(liList);
    };
    fetchBjdCode();
  }, [form.emd.bjcd]);

  // 끝 ----- 팜맵 API ---------------

  // 외부영역 클릭 이벤트 - 주소 필터 닫기
  useOutsideClick(() => setShowFilter(false), selectAdreRef);

  // 상단 주소 영역 클릭
  const onClickSelect = (type) => {
    setShowFilter(true); // 주소 필터 오픈

    if (type === 'sido') {
      setForm(defaultForm);
    }
    if (type === 'signgu') {
      setForm((prev) => ({ ...prev, signgu: {}, emd: {}, li: {} }));
    }
    if (type === 'emd') {
      setForm((prev) => ({ ...prev, emd: {}, li: {} }));
    }
    if (type === 'li') {
      setForm((prev) => ({ ...prev, li: {} }));
    }
  };

  // [공통] List > Item 클릭
  const onClickLiItem = (e) => {
    const { ariaLabel, ariaValueText, ariaValueNow } = e.currentTarget;
    setForm((prev) => ({ ...prev, [ariaLabel]: { name: ariaValueText, bjcd: ariaValueNow } }));
  };

  // 하단 위치 이동 버튼 클릭
  const onClickMoveTo = async () => {
    const keys = Object.keys(form);
    const juso = keys.map((key) => form[key].name).join(' ') || ''; // 주소 문자열 완성
    //  console.log(juso);

    setLiData({ name: form.li.name, bjcd: form.li.bjcd }); // 해당 위치로 이동하기 선택한 리 정보를 따로 저장한다.(structure에 리 정보가 없어서)

    //  const data = await getCoordFromJuso({ juso, proj: 'EPSG:5186' }); // 브이월드 API 요청 (좌표구하기)
    //  const point = data.result.point;
    //  const coord = [point.x || 0, point.y || 0];
    clickMoveTo(juso);
  };

  return (
    <SelectAdreSt.Wrap ref={selectAdreRef}>
      {/* <SelectAdreSt.RegionWrap onClick={() => setShowFilter(!showFilter)}>{'위치변경하기'}</SelectAdreSt.RegionWrap> */}
      <SelectAdreSt.RegionWrap className={`${showFilter && 'active'}`} onClick={() => setShowFilter(true)}>
        <button className={`${form.sido?.name && 'active'}`} onClick={() => onClickSelect('sido')}>
          {form.sido?.name || '시/도'}
        </button>
        <span className="arrow"></span>
        <button className={`${form.signgu?.name && 'active'}`} onClick={() => onClickSelect('signgu')}>
          {form.signgu?.name || '시/군/구'}
        </button>
        <span className="arrow"></span>
        <button className={`${form.emd?.name && 'active'}`} onClick={() => onClickSelect('emd')}>
          {form.emd?.name || '읍/면/동'}
        </button>
        {/* 리 옵션이 있는 경우에만 표시 */}
        {liOption.length > 0 && (
          <>
            <span className="arrow"></span>
            <button className={`${form.li?.name && 'active'}`} onClick={() => onClickSelect('li')}>
              {form.li?.name || '리'}
            </button>
          </>
        )}
      </SelectAdreSt.RegionWrap>

      {showFilter && (
        <>
          <SelectAdreSt.ListWrap>
            <SelectAdreSt.List>
              {/* 시/도 */}
              {!form.sido?.name &&
                sidoOption?.map((ele, idx) => (
                  <SelectAdreSt.Item key={`sido${idx}`} aria-label="sido" aria-valuetext={ele.name} aria-valuenow={ele.bjcd} onClick={(e) => onClickLiItem(e)}>
                    {ele.name}
                  </SelectAdreSt.Item>
                ))}

              {form.sido?.name &&
                !form.signgu?.name &&
                signguOption?.map((ele, idx) => (
                  <SelectAdreSt.Item key={`signgu${idx}`} aria-label="signgu" aria-valuetext={ele.name} aria-valuenow={ele.bjcd} onClick={(e) => onClickLiItem(e)}>
                    {ele.name}
                  </SelectAdreSt.Item>
                ))}

              {form.signgu?.name &&
                liOption.length === 0 &&
                emdOption?.map((ele, idx) => (
                  <SelectAdreSt.Item key={`emd${idx}`} aria-label="emd" aria-valuetext={ele.name} aria-valuenow={ele.bjcd} onClick={(e) => onClickLiItem(e)}>
                    {ele.name}
                  </SelectAdreSt.Item>
                ))}

              {form.emd?.name &&
                liOption?.map((ele, idx) => (
                  <SelectAdreSt.Item key={`li${idx}`} aria-label="li" aria-valuetext={ele.name} aria-valuenow={ele.bjcd} onClick={(e) => onClickLiItem(e)}>
                    {ele.name}
                  </SelectAdreSt.Item>
                ))}
            </SelectAdreSt.List>
          </SelectAdreSt.ListWrap>

          <SelectAdreSt.MoveToBtn>
            <Button text="해당 위치로 이동하기" disabled={!form.emd?.name} fontSize={14} onClick={onClickMoveTo} />
            {/* <button onClick={onClickMoveTo} disabled={!form.emd?.name}>
              해당 위치로 이동하기
            </button> */}
          </SelectAdreSt.MoveToBtn>
        </>
      )}
    </SelectAdreSt.Wrap>
  );
}

export default SelectAddress;
