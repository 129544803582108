import React from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import IcoWarning from '../assets/images/popup/ico-warning.svg';

const Content = {
  Inner: styled.div`
    padding: 16px 0 0;
  `,
  Tip: styled.p`
    color: #00b074;
    font-size: 14px;
    font-weight: 400;
  `,
  ButtonArea: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    > button {
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
  ErrorArea: styled.div`
    width: 100%;
    border-radius: 4px 4px 0 0;
    position: relative;
  `,

  Head: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 40px;
    border-radius: 4px 4px 0 0;
    background: #f8dcda;
    strong {
      font-size: 14px;
      font-weight: 600;
      color: #eb5846;
      margin-right: auto;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: #eb5846;
    }
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      margin-right: 6px;
      background: url(${IcoWarning}) no-repeat center;
    }
  `,
  List: styled.ul`
    max-height: 180px;
    border: 1px solid #e9e7e7;
    border-bottom: 0;
  `,
  Item: styled.li`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 500;
    color: #3f3f3f;
    &:not(:last-child) {
      border-bottom: 1px solid #e9e7e7;
    }
  `,
};

const PopupFailUpload = ({ onClose, failType, errorList }) => {
  return (
    <PopupTemplate
      title={'업로드 실패'}
      width={496}
      $type={3}
      isDimmed={true}
      onClose={onClose}
      footer={
        <>
          {failType === 'pic' ? (
            <Content.ButtonArea>
              <Button width={'full'} text={'확인'} $colorType={'orange'} onClick={onClose} />
            </Content.ButtonArea>
          ) : failType === 'matching' ? (
            <Content.ButtonArea>
              <Button width={'full'} text={'전체 결과 보기'} $colorType={'orange'} />
              <Button width={'full'} text={'닫기'} $colorType={'black'} />
            </Content.ButtonArea>
          ) : failType === 'duplication' ? (
            <Content.ButtonArea>
              <Button width={'full'} text={'전체 결과 보기'} $colorType={'orange'} />
              <Button width={'full'} text={'사진 중복 확인'} $colorType={'black'} />
            </Content.ButtonArea>
          ) : null}
        </>
      }
      content={
        <Content.Inner>
          <Content.ErrorArea>
            <Content.Head>
              <strong>업로드 중 장애로 다음 파일을 업로드하지 못했습니다.</strong>
              <span>{errorList.length}건</span>
            </Content.Head>

            <Content.List className={'cScroll'}>
              {errorList.map((i, index) => {
                return <Content.Item key={index}>{i}</Content.Item>;
              })}
            </Content.List>
          </Content.ErrorArea>
        </Content.Inner>
      }
    />
  );
};

export default PopupFailUpload;
