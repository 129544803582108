import React from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { confirmState } from '../../store/Atoms';
import Button from '../atoms/Button';

const ConfirmStyle = {
  Dimmed: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.5);
  `,
  Wrap: styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1002;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 280px;
    border-radius: 4px;
  `,
  TextArea: styled.div`
    padding: 40px 0;
    color: #3f3f3f;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  `,
  ButtonArea: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 12px;
    button {
      flex: 1;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
};

export interface ConfirmProps {
  width?: number;
  message?: string;
  leftText?: string;
  rightText?: string;
  leftFunc?: () => void;
  rightFunc?: () => void;
  show?: boolean;
}

const Confirm = () => {
  const [info] = useRecoilState(confirmState);
  return (
    <>
      {info.show ? (
        <>
          <ConfirmStyle.Dimmed></ConfirmStyle.Dimmed>
          <ConfirmStyle.Wrap>
            <ConfirmStyle.TextArea dangerouslySetInnerHTML={{ __html: String(info.message) }}></ConfirmStyle.TextArea>
            <ConfirmStyle.ButtonArea>
              {info.leftText ? <Button $colorType={'white'} height={48} text={info.leftText} onClick={info.leftFunc} /> : null}
              {info.rightText ? <Button $buttonType={'large'} height={48} text={info.rightText} onClick={info.rightFunc} /> : null}
            </ConfirmStyle.ButtonArea>
          </ConfirmStyle.Wrap>
        </>
      ) : null}
    </>
  );
};

export default Confirm;
