import React from 'react';
import styled from 'styled-components';
import { PopupStyle } from '../../popup/PopupTemplate';
import LabelValue from '../atoms/LabelValue';

const LotOverlayStyle = {
  ...PopupStyle,
  Wrap: styled.div`
    border: 2px solid #024751;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    width: 275px;
    transform: translate(-50%, calc(-50% - 180px));
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  `,
  Head: styled(PopupStyle.Head)`
    background: #fff;
    padding: 0 20px;
    height: 48px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #d9d9d9;
    box-sizing: border-box;
  `,
  Title: styled(PopupStyle.Title)`
    font-size: 16px;
    color: #001a21;
    font-weight: 600;
  `,
  Inner: styled.div`
    padding: 10px 10px 14px 20px;
  `,
  CloseBtn: styled(PopupStyle.CloseBtn)`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #f3f4f5;
    position: relative;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      width: 12px;
      height: 2px;
      border-radius: 50px;
      background: #3f3f3f;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `,
};

const LotOverlay = ({ objectFarmMap }) => {
  return (
    <LotOverlayStyle.Wrap id={'lotOverlay'}>
      <LotOverlayStyle.Head>
        <LotOverlayStyle.Title>필지 정보</LotOverlayStyle.Title>
        <LotOverlayStyle.CloseBtn type={'button'} onClick={() => objectFarmMap?.clearClickFeature()}>
          <span className={'hidden'}></span>
        </LotOverlayStyle.CloseBtn>
      </LotOverlayStyle.Head>
      <LotOverlayStyle.Cont>
        <LotOverlayStyle.Inner>
          <div className={'cScroll'} style={{ height: 198 }}>
            <LabelValue label={'아이디'} value={'427702592401107'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'유아이디'} value={'13832083'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'분류명'} value={'과수'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'법정동코드'} value={'5177025924'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'법정동주소'} value={'강원특별자치도 정선군 신동읍 방제리'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'대표PNU'} value={'5177025924104430187'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'대표 지목'} value={'전'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'면적'} value={'2423.55224258'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'지적일치율'} value={'62.36'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'판독영상'} value={'항공정사영상'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'영상촬영일자'} value={'2022-12-30'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'갱신일자'} value={'2023-12-28'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'갱신유형'} value={'변경'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'변경사유'} value={'개선'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'경지정리여부'} value={'-'} padding={6} marginbottom={0} fontSize={13} />
            <LabelValue label={'전차_유아이디'} value={'13832083'} padding={8} marginbottom={0} fontSize={13} />
            <LabelValue label={'전차_분류명'} value={'밭'} padding={6} marginbottom={0} fontSize={13} />
          </div>
        </LotOverlayStyle.Inner>
      </LotOverlayStyle.Cont>
    </LotOverlayStyle.Wrap>
  );
};

export default LotOverlay;
