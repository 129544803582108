import React from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Input from '../components/atoms/Input';
import LabelValue from '../components/atoms/LabelValue';

const Content = {
  Inner: styled.div`
    padding: 10px 0 16px;
  `,
};

const PopupLotInfo = ({ onClose }) => {
  return (
    <PopupTemplate
      title={'필지 정보'}
      width={356}
      isDimmed={true}
      onClose={onClose}
      content={
        <Content.Inner>
          <LabelValue label={'아이디'} value={'427702592401107'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'유아이디'} value={'13832083'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'분류명'} value={'과수'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'법정동코드'} value={'5177025924'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'법정동주소'} value={'강원특별자치도 정선군 신동읍 방제리'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'대표PNU'} value={'5177025924104430187'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'대표 지목'} value={'전'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'면적'} value={'2423.55224258'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'지적일치율'} value={'62.36'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'판독영상'} value={'항공정사영상'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'영상촬영일자'} value={'2022-12-30'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'갱신일자'} value={'2023-12-28'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'갱신유형'} value={'변경'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'변경사유'} value={'개선'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'경지정리여부'} value={'-'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'전차_유아이디'} value={'13832083'} padding={8} marginbottom={0} border={true} fontSize={14} />
          <LabelValue label={'전차_분류명'} value={'밭'} padding={8} marginbottom={0} border={true} fontSize={14} />
        </Content.Inner>
      }
    />
  );
};

export default PopupLotInfo;
