import React, { SetStateAction, useState } from 'react';
import DatePicker, { Calendar, DateObject } from 'react-multi-date-picker';
import IcoCal from '../../assets/images/input/ico-cal.svg';
import styled from 'styled-components';

const RangePickerStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    .rmdp-container {
      .rmdp-input {
        width: 172px;
        height: 48px;
        border: 1px solid #e5e5ec;
        margin: 0;
        padding: 0 0 0 20px;
        color: #001a21;
        font-size: 16px;
        font-weight: 400;
        background: #fff url(${IcoCal}) no-repeat right 20px center;
      }
    }
  `,
  Dash: styled.span`
    width: 10px;
    height: 2px;
    background: #d3d3d4;
    margin: 0 10px;
  `,
};

const RangePicker = ({ date, onChangeDate }: { date: { from: DateObject; to: DateObject }; onChangeDate: (e, key) => void }) => {
  return (
    <RangePickerStyle.Wrap>
      <DatePicker
        months={['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']}
        weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        showOtherDays={true}
        headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
        monthYearSeparator=" "
        value={date?.from}
        onChange={(e) => onChangeDate(e, 'from')}
      />
      <RangePickerStyle.Dash></RangePickerStyle.Dash>
      <DatePicker
        months={['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']}
        weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        showOtherDays={true}
        headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
        monthYearSeparator=" "
        value={date?.to}
        onChange={(e) => onChangeDate(e, 'to')}
      />
    </RangePickerStyle.Wrap>
  );
};

export default RangePicker;
