// 이메일 체크
export function checkEmail(str) {
  // 기본 정규식
  const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;
  const RFCSpeciall = '!#$%&*+-/=?^_`{|}~'; //RFC 표준에서 허용되는 특수문자
  const special = '._+-'; // Gmail, Outlook 등 주로 허용하는 특수문자 ([%]는 일부에서만 허용)

  // 정규식을 동적으로 생성(특수문자)
  const emailRegex = new RegExp(`^[a-zA-Z0-9${special}]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$`, 'g');

  // 특정 도메인 설정
  const domain = 'example.com';
  const domainReg = new RegExp(`^[a-zA-Z0-9${special}]+@${domain.replace('.', '\\.')}$`, 'g');

  if (emailRegex.test(str)) {
    return true;
  } else {
    return false;
  }
}

// 특수 문자 체크
export function checkSpecial(str) {
  const regExp = /[!?@#$%^&*():;+-=~{}<>\_\[\]\|\\\"\'\,\.\/\`\₩]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
}
