/*
   새로운 api 파일 추가시, import-export 작업 필요
*/
import * as map from './map';
import * as address from './address';
import * as image from './image';
import * as project from './project';
import * as member from './member';
import * as farmMap from './farmMap';

const api = {
  map,
  address,
  image,
  project,
  member,
  farmMap,
};
export default api;
