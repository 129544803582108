import styled from 'styled-components';
import IcoCloseWhite from 'assets/images/ico-close-white.svg';
import IcoCheckActive from 'assets/images/cropInfoBox/ico-check-active.svg';

export const CropInfoSt = {
  Wrap: styled.div<{ cropCheckWidth?: number }>`
    width: 100%;
    position: fixed;
    top: 80px;
    z-index: 11;
    right: 17px;
    left: auto;
    transition: 0.4s;
    &.hide {
      width: ${(p) => (p.cropCheckWidth ? `calc(304px + 90px + ${p.cropCheckWidth}px)` : `auto`)}; // 필지체크, 작물체크, 카드영역
      width: calc(304px + 180px); // 필지체크, 작물체크, 카드영역
    }
    @media (min-width: 1800px) {
      width: calc(100% - 135px - 16px); // 전체화면 - padding
    }
  `,
  Header: styled.div`
    /* min-width: 484px; */
    height: 52px;
    box-sizing: border-box;
    background-color: #024751;
    padding: 0 16px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px 4px 0 0;
    .title {
      color: #09d585;
      font-size: 16px;
      font-weight: 600;
    }
    .closeBtn {
      width: 20px;
      height: 20px;
      background: url(${IcoCloseWhite}) no-repeat center;
      cursor: pointer;
    }
  `,
  ContainerWrap: styled.div`
    display: flex;
    flex-direction: row;
    &.hide {
      width: 485px;
    }
  `,
  Container: styled.div`
    flex: 1;
    overflow: hidden;
    /* min-width: 484px; */
    height: calc(100vh - 52px - 83px - 35px);
    background-color: #f0f0f0;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px #00000059;
    display: flex;
    flex-direction: row;

    > * {
      flex-shrink: 0;
    }
  `,
  TypeCheckWrap: styled.div`
    width: 90px;
    height: 100%;
    background-color: #fff;
    padding: 12px 7px 0 20px;
    border-right: 1px solid #d9d9d9;
    box-sizing: border-box;
    display: flex;
    ul {
      display: flex;
      flex-direction: column;
      gap: 14px;
      li {
      }
    }
  `,
  CropCheckWrap: styled.div`
    min-width: 90px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #d9d9d9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    ul {
      padding: 12px 7px 12px 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 14px;
      &:nth-child(2) {
        border-top: 1px solid #d9d9d9;
      }
      li {
      }
    }
  `,
  ContentWrap: styled.div<{ cropCheckWidth?: number }>`
    /* max-width: ${(p) => (p.cropCheckWidth ? `calc(100% - 90px - ${p.cropCheckWidth}px)` : `auto`)}; // 필드 체크영역, 작물 체크 영역 빼고. */
    width: 304px;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
    .pageListWrap {
      width: 100%;
      height: 48px;
      border-top: 1px solid #d9d9d9;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
  Content: styled.div`
    height: calc(100% - 48px);
    padding: 6px 12px 12px 12px;
    box-sizing: border-box;
    .selectAll {
      width: 100%;
      height: 44px;
      background-color: #fff;
      padding: 0 16px;
      border: 1px solid #e9e7e7;
      box-sizing: border-box;
      box-shadow: 0px 4px 6px 0px #00000014;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .selectWrap {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #00b074;
        font-weight: 500;
        .num {
          width: 22px;
          height: 20px;
          padding: 6px 8px;
          box-sizing: border-box;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #00b074;
          color: #fff;
          font-size: 12px;
        }
      }
    }
    .filterWrap {
      margin-top: 11px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btnWrap {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
        button {
          height: 100%;
          padding: 10px;
          box-sizing: border-box;
          background-color: #fff;
          border: 1px solid #e9e7e7;
          border-radius: 20px;
          color: #7e8888;
          display: flex;
          gap: 6px;
          align-items: center;
          font-weight: 500;
          /* font-size: 12px; */
          &.active {
            border: 1px solid #00b074;
            color: #00b074;
            /* &::before {
              content: '';
              display: inline-block;
              width: 9px;
              height: 6px;
              background: url(${IcoCheckActive}) no-repeat center;
              background-size: cover;
            } */
          }
        }
      }
      .totalWrap {
        font-size: 12px;
        color: #00b074;
        font-weight: 500;
        .total {
          color: #024751;
          font-weight: 600;
        }
      }
    }
  `,
  CardWrap: styled.div`
    width: 100%;
    height: calc(100% - 49px - 44px);
    margin-top: 8px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
  `,
  Card: styled.div`
    position: relative;
    flex-shrink: 0;
    padding: 2px;
    width: 100%;
    height: 218px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e9e7e7;
    box-sizing: border-box;
    &.active {
      background: #00b074;
    }
  `,
  CardHead: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    height: 44px;
    box-sizing: border-box;
    border-bottom: 1px solid #e9e7e7;
    background: #fff;
    label {
      cursor: pointer;
    }
  `,
  CardCont: styled.div`
    background: #fff;
    height: calc(100% - 108px);
    box-sizing: border-box;
    border-bottom: 1px solid #e9e7e7;
    display: flex;
    align-items: center;
    position: relative;
    .imgCount {
      position: absolute;
      bottom: 16px;
      right: 22px;
      background-color: #ff7600;
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      color: #fff;
      font-size: 12px;
    }
  `,
  CardFoot: styled.div`
    height: 64px;
    background: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  CardImageArea: styled.div`
    flex: 1;
    padding: 0 14px;
    height: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    > img {
      flex: 1;
      height: 100%;
      border-radius: 4px;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  CardLabelValueArea: styled.div`
    width: calc(100% - 28px);
    padding: 0 14px;
  `,
};
