import React, { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

const HelpMessageStyle = styled.p<{ icon?: string }>`
  display: flex;
  align-items: center;
  color: #7e8888;
  font-size: 14px;
  font-weight: 400;
  b {
    color: #00b074;
    font-weight: 500;
    margin: 0 3px;
  }
  a {
    color: #00b074;
    text-decoration: underline;
  }
  ${(p) =>
    p.icon &&
    `
      &:before{content:'';width:18px;height:18px;background:url(${p.icon})no-repeat center;margin-right:4px;}
    `}
`;
const HelpMessage = ({ style, message, icon }: { style?: CSSProperties; message?: any; icon?: string }) => {
  return <HelpMessageStyle style={style} icon={icon} dangerouslySetInnerHTML={{ __html: message }}></HelpMessageStyle>;
};

export default HelpMessage;
