import * as apiUrl from './apiUrl';
import { request, requestFormData } from '../../../service/farmEzCommon';

//사진 파일 업로드
export const uploadImages = async (files) => {
  const formData = new FormData();
  files.map((file) => {
    formData.append('files', file);
  });
  const { data } = await requestFormData.post(apiUrl.image.upload, formData);
  return data;
};
