import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import LabelInput, { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import Button from '../../../../components/atoms/Button';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';
import { useRecoilState } from 'recoil';
import { formState } from '../../../../store/Atoms';
import Input from '../../../../components/atoms/Input';

const Step1 = ({ onTogglePopup, onNextStep }) => {
  const [form, setForm] = useRecoilState(formState);

  // 다음 단계 버튼 disabled 값
  const isDisabled = () => {
    return !(form.name && form.type && form.zoneCode && form.address && form.area && form.phone && form.email && form.department);
  };

  return (
    <div style={{ height: '100%', width: 1130 }}>
      <div className={'cScroll'} style={{ height: 'calc(100% - 102px)' }}>
        <Section>
          <LabelInput
            label={'발주처(기관명)'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'발주처명을 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, name: e.target.value });
            }}
            value={form?.name || ''}
          />
        </Section>
        <Section>
          <LabelInput
            label={'기관 유형'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'기관 유형을 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, type: e.target.value });
            }}
            value={form?.type || ''}
          />
        </Section>
        <Section>
          <LabelInputStyle.Wrap $alignItem={'flex-start'}>
            <LabelInputStyle.Key required={true} $alignItem={'flex-start'}>
              주소
            </LabelInputStyle.Key>
            <div>
              <LabelInputStyle.Wrap style={{ marginBottom: 6 }}>
                <Input readOnly={true} value={form?.zoneCode || ''} width={160} height={48} placeholder={'우편번호'} />
                <Button $buttonType={'small'} text={'주소찾기'} onClick={onTogglePopup} height={48} fontSize={14} padding={20} style={{ marginLeft: 6 }} />
              </LabelInputStyle.Wrap>
              <LabelInputStyle.Wrap style={{ marginBottom: 6 }}>
                <Input readOnly={true} value={form?.address || ''} width={532} height={48} placeholder={'도로명 또는 지번'} />
              </LabelInputStyle.Wrap>
              <LabelInputStyle.Wrap>
                <Input
                  width={532}
                  height={48}
                  placeholder={'상세 주소'}
                  onChange={(e) => {
                    setForm({ ...form, detailAddress: e.target.value });
                  }}
                  value={form?.detailAddress || ''}
                />
              </LabelInputStyle.Wrap>
            </div>
          </LabelInputStyle.Wrap>
        </Section>
        <Section>
          <LabelInput
            label={'행정구역'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'행정구역을 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, area: e.target.value });
            }}
            value={form?.area || ''}
          />
        </Section>
        <Section>
          <LabelInput
            label={'전화번호'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'전화번호를 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, phone: e.target.value });
            }}
            value={form?.phone || ''}
          />
        </Section>
        <Section>
          <LabelInput
            label={'이메일'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'이메일을 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, email: e.target.value });
            }}
            value={form?.email || ''}
          />
        </Section>
        <Section>
          <LabelInput
            label={'담당부서'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'담당부서를 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, department: e.target.value });
            }}
            value={form?.department || ''}
          />
        </Section>
      </div>
      <Section>
        <NextStep>
          <Button $buttonType={'large'} text={'다음 단계'} $icoPosition={'right'} icon={IcoStepNext} $iconSize={16} $iconSpacing={6} style={{ marginRight: 6 }} disabled={isDisabled()} onClick={() => onNextStep(1)} />
        </NextStep>
      </Section>
    </div>
  );
};

export default Step1;
