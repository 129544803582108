import React, { useEffect, useState } from 'react';
import AdminSubContainer from '../../../components/templates/Container/AdminSubContainer';
import { useRecoilState } from 'recoil';
import { confirmState, formState } from '../../../store/Atoms';
import { useNavigate } from 'react-router-dom';
import PopupPostCode from '../../../popup/PopupPostCode';
import { NextStep, Section, TabContentStyle } from '../../../components/atoms/TabContent';
import LabelValue from '../../../components/atoms/LabelValue';
import Button from '../../../components/atoms/Button';
import LabelInput from '../../../components/atoms/LabelInput';
import LabelSelect from '../../../components/atoms/LabelSelect';
import IcoStepDone from '../../../assets/images/button/ico-step-done.svg';

const initForm = {
  email: 'is.mv@movements.kr',
  name: '오인섭',
  client: 1,
  password: '',
  passwordConfirm: '',
};

const InspectorDetail = () => {
  const navigate = useNavigate();
  const [form, setForm] = useRecoilState(formState);
  const [isEditMode, setIsEditMode] = useState(false);
  const [confirm, setConfirm] = useRecoilState(confirmState);

  const [option, setOption] = useState([
    { text: '발주처1', value: 1, active: false },
    { text: '발주처2', value: 2, active: false },
    { text: '발주처3', value: 3, active: false },
    { text: '발주처4', value: 4, active: false },
  ]);

  //탭 변경시 form에 저장된 값 가져와서 option 선택
  useEffect(() => {
    const newArr = [...option];
    newArr.map((i) => {
      i.active = String(form.client) === String(i.value);
    });
    setOption(newArr);
  }, [isEditMode]);

  //form 객체 세팅(recoil 저장)
  useEffect(() => {
    setForm(initForm);
  }, []);

  //수정모드 토글
  const onToggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  //계정삭제
  const onRemove = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: `'${form.email}'<br/>계정을 삭제하시겠습니까?`,
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        //TODO 삭제 api 추가예정
        setConfirm({ ...confirm, show: false });
      },
    });
  };

  return (
    <AdminSubContainer
      title={isEditMode ? '검수자 정보 수정' : '검수자 정보'}
      onNavigate={() => navigate('/inspector')}
      content={
        <TabContentStyle.Content style={{ height: 'calc(100% - 40px)' }}>
          <TabContentStyle.Inner>
            <TabContentStyle.ContBox>
              {isEditMode ? (
                <>
                  <Section style={{ width: 446 }}>
                    <LabelInput
                      label={'이메일(ID)'}
                      $labelWidth={126}
                      inputWidth={217}
                      inputHeight={48}
                      required={true}
                      placeholder={'이메일을 입력하세요'}
                      value={form?.email || ''}
                      onChange={(e) => {
                        setForm({ ...form, email: e.target.value });
                      }}
                      rightButton={
                        <Button
                          $buttonType={'medium'}
                          text={'중복 확인'}
                          width={92}
                          height={48}
                          style={{ marginLeft: 6 }}
                          onClick={() => {
                            //TODO 중복확인 API
                          }}
                        />
                      }
                    />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelInput
                      label={'이름'}
                      $labelWidth={126}
                      inputWidth={315}
                      inputHeight={48}
                      required={true}
                      placeholder={'이름을 입력하세요'}
                      value={form?.name || ''}
                      onChange={(e) => {
                        setForm({ ...form, name: e.target.value });
                      }}
                    />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelSelect
                      label={'발주처'}
                      $labelWidth={126}
                      inputWidth={315}
                      inputHeight={48}
                      required={true}
                      placeholder={'발주처를 선택하세요'}
                      option={option}
                      $borderColor={'#E5E5EC'}
                      onChange={(e) => {
                        const newArr = [...option];
                        newArr.map((i) => {
                          i.active = String(i.value) === e.target.ariaValueText;
                        });
                        setForm({ ...form, client: e.target.ariaValueText });
                        setOption(newArr);
                      }}
                    />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelInput
                      type={'password'}
                      label={'비밀번호'}
                      $labelWidth={126}
                      inputWidth={315}
                      inputHeight={48}
                      required={true}
                      placeholder={'비밀번호를 입력하세요'}
                      value={form?.password || ''}
                      onChange={(e) => {
                        setForm({ ...form, password: e.target.value });
                      }}
                    />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelInput
                      type={'password'}
                      label={'비밀번호 확인'}
                      $labelWidth={126}
                      inputWidth={315}
                      inputHeight={48}
                      required={true}
                      placeholder={'비밀번호 확인'}
                      value={form?.passwordConfirm || ''}
                      onChange={(e) => {
                        setForm({ ...form, passwordConfirm: e.target.value });
                      }}
                    />
                    <NextStep>
                      <Button $buttonType={'large'} text={'수정 완료'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} style={{ marginRight: 6 }} />
                    </NextStep>
                  </Section>
                </>
              ) : (
                <>
                  <Section style={{ width: 446 }}>
                    <LabelValue label={'이메일(ID)'} value={form?.email || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelValue label={'이름'} value={form?.name || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelValue label={'발주처'} value={option.filter(({ value }) => String(value) === String(form.client))[0]?.text || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelValue label={'비밀번호'} value={'● ● ● ● ● ● ● ●'} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
                  </Section>
                  <div style={{ display: 'flex', marginTop: 20 }}>
                    <Button $buttonType={'large'} text={'수정하기'} style={{ marginRight: 8 }} onClick={onToggleEditMode} />
                    <Button $colorType={'orange'} text={'계정 삭제'} onClick={onRemove} />
                  </div>
                </>
              )}
            </TabContentStyle.ContBox>
          </TabContentStyle.Inner>
        </TabContentStyle.Content>
      }
    />
  );
};

export default InspectorDetail;
