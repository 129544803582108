import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import LabelInput, { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import LabelSelect from '../../../../components/atoms/LabelSelect';
import HelpMessage from '../../../../components/atoms/HelpMessage';
import IcoHelp from '../../../../assets/images/etc/ico-help-message.svg';
import Button from '../../../../components/atoms/Button';
import IcoFileUpload from '../../../../assets/images/button/ico-file-upload.svg';
import ScrollTable from '../../../../components/atoms/ScrollTable';
import Checkbox from '../../../../components/atoms/Checkbox';
import NoData from '../../../../components/atoms/NoData';
import IcoNoLot from '../../../../assets/images/noData/ico-no-lot.svg';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';
import { useRecoilState } from 'recoil';
import { formState } from '../../../../store/Atoms';
import { PagingTableStyle } from '../../../../components/atoms/PagingTable';
import TdEllipsis from '../../../../components/atoms/TdEllipsis';
import RangePicker from '../../../../components/atoms/RangePicker';
import { DateObject } from 'react-multi-date-picker';

const Step1 = ({ onTogglePopup, onNextStep }) => {
  const [form, setForm] = useRecoilState(formState);
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [dummy, setDummy] = useState([
    { id: 457703503254370, name: '아스파라거스', address: '제주 서귀포시 호근서호로 20-14제주 서귀포시 호근서호로 20-14제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
    { id: 457703503254371, name: '당근', address: '제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
    { id: 457703503254372, name: '양배추', address: '제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
    { id: 457703503254373, name: '천혜향', address: '제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
    { id: 457703503254374, name: '무', address: '제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
    { id: 457703503254375, name: '배추', address: '제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
    { id: 457703503254376, name: '감', address: '제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
    { id: 457703503254377, name: '감자', address: '제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
    { id: 457703503254378, name: '고구마', address: '제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
    { id: 457703503254379, name: '수박', address: '제주 서귀포시 호근서호로 20-14', remain: 10, checked: false },
  ]);

  const [option, setOption] = useState([
    { text: '발주처1', value: 1, active: false },
    { text: '발주처2', value: 2, active: false },
    { text: '발주처3', value: 3, active: false },
    { text: '발주처4', value: 4, active: false },
  ]);

  // 다음 단계 버튼 disabled 값
  const isDisabled = () => {
    return !(form.projectName && form.client && form.projectId && form.date.from && form.date.to);
  };

  //탭 변경시 form에 저장된 값 가져와서 option 선택
  useEffect(() => {
    const newArr = [...option];
    newArr.map((i) => {
      i.active = String(form.client) === String(i.value);
    });
    setOption(newArr);
  }, []);

  //전체 선택/해제
  const onCheckAll = () => {
    const newArr = [...dummy];
    if (newArr.length === newArr.filter(({ checked }) => checked).length) {
      newArr.map((i) => {
        i.checked = false;
      });
    } else {
      newArr.map((i) => {
        i.checked = true;
      });
    }
    setDummy(newArr);
  };

  //체크박스 클릭
  const onCheck = (id) => {
    const newArr = [...dummy];
    newArr.find((i) => i.id === id)!.checked = !newArr.find((i) => i.id === id)?.checked;
    setDummy(newArr);
  };

  //체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    if (dummy.filter(({ checked }) => checked).length === dummy.length) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  }, [dummy.filter(({ checked }) => checked).length]);

  const onChangeDate = (e, type) => {
    if (type === 'from') {
      setForm({ ...form, date: { ...form.date, from: e } });
    } else {
      setForm({ ...form, date: { ...form.date, to: e } });
    }
  };

  return (
    <>
      <Section>
        <LabelInput
          label={'사업장명'}
          inputWidth={1116}
          inputHeight={48}
          required={true}
          placeholder={'사업장명을 입력하세요'}
          onChange={(e) => {
            setForm({ ...form, projectName: e.target.value });
          }}
          value={form?.projectName || ''}
        />
      </Section>
      <Section style={{ display: 'flex' }}>
        <div style={{ width: 635, borderRight: '1px solid #E5E5EC' }}>
          <LabelSelect
            label={'발주처'}
            inputWidth={320}
            inputHeight={48}
            required={true}
            placeholder={'발주처를 선택하세요'}
            option={option}
            $borderColor={'#E5E5EC'}
            onChange={(e) => {
              const newArr = [...option];
              newArr.map((i) => {
                i.active = String(i.value) === e.target.ariaValueText;
              });
              setForm({ ...form, client: e.target.ariaValueText });
              setOption(newArr);
            }}
          />
          <HelpMessage message={'발주처가 없는 경우 <b>[발주처 관리]</b>에서 추가하세요.'} style={{ marginLeft: 155, marginTop: 10 }} icon={IcoHelp} />
        </div>
        <div style={{ marginLeft: 40 }}>
          <LabelInput
            label={'프로젝트 ID'}
            inputWidth={320}
            inputHeight={48}
            required={true}
            placeholder={'프로젝트 ID를 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, projectId: e.target.value });
            }}
            value={form?.projectId || ''}
            rightButton={
              <Button
                $buttonType={'medium'}
                text={'중복 확인'}
                width={92}
                height={48}
                style={{ marginLeft: 6 }}
                onClick={() => {
                  //TODO 중복확인 API
                }}
              />
            }
          />
        </div>
      </Section>
      <Section>
        <LabelInputStyle.Wrap style={{ height: '100%' }} $alignItem={'flex-start'}>
          <LabelInputStyle.Key required={true}>사업 기간</LabelInputStyle.Key>
          <RangePicker date={form.date} onChangeDate={onChangeDate} />
        </LabelInputStyle.Wrap>
      </Section>
      <Section style={{ height: 'calc(100% - 297px)' }}>
        <LabelInputStyle.Wrap style={{ height: '100%' }} $alignItem={'flex-start'}>
          <LabelInputStyle.Key required={true}>필지 정보</LabelInputStyle.Key>
          <div style={{ width: '100%', height: '100%' }}>
            <div style={{ display: 'flex', marginBottom: 14 }}>
              <HelpMessage message={'<b>주소 검색</b> 또는 <b>shp 파일</b>을 업로드해서 필지를 추가하세요.'} />
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <Button $buttonType={'medium'} text={'필지 주소 등록'} icon={IcoFileUpload} $iconSize={20} $iconSpacing={6} style={{ marginRight: 6 }} onClick={onTogglePopup} />
                <Button $buttonType={'medium'} text={'필지 SHP 업로드'} icon={IcoFileUpload} $iconSize={20} $iconSpacing={6} />
              </div>
            </div>
            <ScrollTable
              height={'calc(100% - 192px)'}
              caption={'필지 정보'}
              colgroup={[60, 'auto', 180, 170, 100, 100]}
              thead={
                <tr>
                  <th scope={'col'}>
                    <Checkbox onChange={onCheckAll} id={'checkAll'} checked={isAllCheck} />
                  </th>
                  <th scope={'col'}>주소</th>
                  <th scope={'col'}>팜맵 ID</th>
                  <th scope={'col'}>대표 작물</th>
                  <th scope={'col'}>잔여량</th>
                  <th scope={'col'}></th>
                </tr>
              }
              tbody={
                <>
                  {dummy.length ? (
                    dummy.map((i: any) => {
                      return (
                        <tr key={i.id} className={i.checked ? 'checked' : undefined}>
                          <td>
                            <Checkbox id={'lot_' + i.id} checked={i.checked} onChange={() => onCheck(i.id)} />
                          </td>
                          <td>
                            <TdEllipsis str={i.address} />
                          </td>
                          <td>{i.id}</td>
                          <td>{i.name}</td>
                          <td>{i.remain}</td>
                          <td>
                            <PagingTableStyle.BtnRowRemove className={`btnRemove ${i.checked ? 'checked' : undefined}`} type={'button'}></PagingTableStyle.BtnRowRemove>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <NoData text={'등록된 필지가 없습니다.<br/>필지 주소 또는 shp 파일을 업로드해주세요.'} color={'#3F3F3F'} icon={IcoNoLot} $iconSize={54} padding={[50, 50]} />
                      </td>
                    </tr>
                  )}
                </>
              }
              $hasTotal={true}
              total={dummy.length + '필지'}
              checkLength={dummy.filter(({ checked }) => checked).length}
              $totalBtnName={'필지 삭제'}
              onRemove={() => {}}
            />
            <NextStep>
              <Button $buttonType={'large'} text={'다음 단계'} $icoPosition={'right'} icon={IcoStepNext} $iconSize={16} $iconSpacing={6} style={{ marginRight: 6 }} disabled={isDisabled()} onClick={() => onNextStep(1)} />
            </NextStep>
          </div>
        </LabelInputStyle.Wrap>
      </Section>
    </>
  );
};

export default Step1;
