import React, { useEffect, useState } from 'react';
import IcoPage4 from '../../../assets/images/nav/ico-menu4-page.svg';
import IcoNoProject from '../../../assets/images/noData/ico-no-project.svg';
import PagingTable, { PagingTableStyle } from '../../../components/atoms/PagingTable';
import AdminContainer from '../../../components/templates/Container/AdminContainer';
import Checkbox from '../../../components/atoms/Checkbox';
import RoundBadge from '../../../components/atoms/RoundBadge';
import TdEllipsis from '../../../components/atoms/TdEllipsis';
import NoData from '../../../components/atoms/NoData';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../../components/molcures/SearchInput';
import { useRecoilState } from 'recoil';
import { confirmState } from '../../../store/Atoms';
import PopupSignUpInspector from '../../../popup/PopupSignUpInspector';

const InspectorList = () => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dummy, setDummy] = useState([
    { id: 1, name: '오인섭', email: 'is.mv@movements.kr', client: '한국 농협', checked: false },
    { id: 2, name: '오인섭', email: 'is.mv@movements.kr', client: '제주 농협', checked: false },
    { id: 3, name: '오인섭', email: 'is.mv@movements.kr', client: '전남도청', checked: false },
    { id: 4, name: '오인섭', email: 'is.mv@movements.kr', client: '한국 농협', checked: false },
    { id: 5, name: '오인섭', email: 'is.mv@movements.kr', client: '한국 농협', checked: false },
    { id: 6, name: '오인섭', email: 'is.mv@movements.kr', client: '한국 농협', checked: false },
    { id: 7, name: '오인섭', email: 'is.mv@movements.kr', client: '한국 농협', checked: false },
    { id: 8, name: '오인섭', email: 'is.mv@movements.kr', client: '한국 농협', checked: false },
    { id: 9, name: '오인섭', email: 'is.mv@movements.kr', client: '한국 농협', checked: false },
    { id: 10, name: '오인섭', email: 'is.mv@movements.kr', client: '한국 농협', checked: false },
  ]);

  //팝업 상태관리
  const [popupState, setPopState] = useState({
    signUp: false,
  });

  //팝업 토글
  const onTogglePopup = (key) => {
    setPopState({ ...popupState, [key]: !popupState[key] });
  };

  //전체 선택/해제
  const onCheckAll = () => {
    const newArr = [...dummy];
    if (newArr.length === newArr.filter(({ checked }) => checked).length) {
      newArr.map((i) => {
        i.checked = false;
      });
    } else {
      newArr.map((i) => {
        i.checked = true;
      });
    }
    setDummy(newArr);
  };

  //체크박스 클릭
  const onCheck = (id) => {
    const newArr = [...dummy];
    newArr.find((i) => i.id === id)!.checked = !newArr.find((i) => i.id === id)?.checked;
    setDummy(newArr);
  };

  //체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    if (dummy.filter(({ checked }) => checked).length === dummy.length) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  }, [dummy.filter(({ checked }) => checked).length]);

  return (
    <AdminContainer
      title={'검수자 관리'}
      icon={IcoPage4}
      headRight={
        <button type={'button'} style={{ color: '#00B074', fontSize: 16, fontWeight: 500 }} onClick={() => onTogglePopup('signUp')}>
          + 검수자 추가
        </button>
      }
      content={
        <>
          <SearchInput onChange={() => {}} onClick={() => {}} inputWidth={320} height={48} placeholder={'이름 또는 E-mail로 검색'} />
          <PagingTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            caption={'검수자 목록'}
            colgroup={[80, 200, 262, 'auto', 70]}
            totalPages={100}
            thead={
              <tr>
                <th scope={'col'}>
                  <Checkbox id={'inspectorListAll'} onChange={onCheckAll} checked={isAllCheck} />
                </th>
                <th scope={'col'}>이름</th>
                <th scope={'col'}>이메일</th>
                <th scope={'col'}>발주처</th>
                <th scope={'col'}></th>
              </tr>
            }
            tbody={
              <>
                {dummy.length
                  ? dummy.map((i) => {
                      return (
                        <tr key={i.id} className={i.checked ? 'checked' : undefined}>
                          <td>
                            <Checkbox id={'inspectorList_' + i.id} checked={i.checked} onChange={() => onCheck(i.id)} />
                          </td>
                          <td onClick={() => navigate('/inspector/detail')}>{i.name}</td>
                          <td onClick={() => navigate('/inspector/detail')}>{i.email}</td>
                          <td onClick={() => navigate('/inspector/detail')}>{i.client}</td>
                          <td>
                            <PagingTableStyle.BtnRowRemove className={`btnRemove ${i.checked ? 'checked' : undefined}`} type={'button'}></PagingTableStyle.BtnRowRemove>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </>
            }
            hasTotal={true}
            checkLength={dummy.filter(({ checked }) => checked).length}
            $totalBtnName={'삭제'}
          />
          {popupState.signUp ? <PopupSignUpInspector onClose={() => onTogglePopup('signUp')} /> : null}
        </>
      }
    />
  );
};

export default InspectorList;
