import Map from 'pages/Map';
import PopupLargeImage from 'popup/PopupLargeImage';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { popupLargeImgState } from 'store/Atoms';
import Navigation from '../Nav/Navigation';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { confirmState, loadingState } from 'store/Atoms';
import Confirm from '../Confirm';
import Loading from 'components/atoms/Loading';

const ContainerSt = {
  Container: styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
  `,
  NavWrap: styled.div`
    height: 100%;
  `,
  ContWrap: styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
  `,
};

function Container({ getUserRole }) {
  const confirmInfo = useRecoilValue(confirmState);
  const loadingStatus = useRecoilValue(loadingState);
  const [popLarge, setPopLarge] = useRecoilState(popupLargeImgState);

  console.log('role', getUserRole());

  return (
    <>
      <ContainerSt.Container>
        {/* <Map /> */}

        {/* 권한에 따라 navigation 바가 보여짐 */}
        <ContainerSt.NavWrap>
          <Navigation />
        </ContainerSt.NavWrap>

        {/* 라우터 children 부분 */}
        <ContainerSt.ContWrap>
          <Outlet />
        </ContainerSt.ContWrap>

        {/* 로딩바 */}
        {loadingStatus.show ? <Loading /> : null}
        {/* 확인창 */}
        {confirmInfo.show ? <Confirm /> : null}
        {/* 사진 크게보기 팝업 */}
        {popLarge.open && <PopupLargeImage close={() => setPopLarge({ open: false })} />}
      </ContainerSt.Container>
    </>
  );
}

export default Container;
