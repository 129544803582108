import React, { useState } from 'react';
import IcoPage3 from '../../../assets/images/nav/ico-menu3-page.svg';
import IcoNoProject from '../../../assets/images/noData/ico-no-project.svg';
import PagingTable from '../../../components/atoms/PagingTable';
import AdminContainer from '../../../components/templates/Container/AdminContainer';
import Checkbox from '../../../components/atoms/Checkbox';
import RoundBadge from '../../../components/atoms/RoundBadge';
import TdEllipsis from '../../../components/atoms/TdEllipsis';
import NoData from '../../../components/atoms/NoData';
import { useNavigate } from 'react-router-dom';

const WorkPlaceList = () => {
  const navigate = useNavigate();
  const [cur, setCur] = useState(1);

  const [dummy, setDummy] = useState([
    { id: 1, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 2, progress: 1, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
    { id: 2, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 1, progress: 1, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
    { id: 3, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 3, progress: 1, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
    { id: 4, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 4, progress: 3, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
    { id: 5, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 5, progress: 1, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
    { id: 6, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 2, progress: 4, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
    { id: 7, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 2, progress: 1, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
    { id: 8, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 2, progress: 1, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
    { id: 9, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 2, progress: 1, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
    { id: 10, name: '제주에인감귤밭', address: '제주 서귀포시 호근서호로 20-14', client: '농림수산부', round: 2, progress: 1, startDate: '2024/01/01', endDate: '2024/10/10', checked: false },
  ]);

  return (
    <AdminContainer
      title={'사업장 관리'}
      icon={IcoPage3}
      headRight={
        <button type={'button'} style={{ color: '#00B074', fontSize: 16, fontWeight: 500 }} onClick={() => navigate('/workPlace/add')}>
          + 사업장 추가
        </button>
      }
      content={
        <PagingTable
          totalPages={100}
          currentPage={cur}
          setCurrentPage={setCur}
          caption={'사업장 목록'}
          colgroup={[40, 'auto', 'auto', 180, 120, 120, 200]}
          hasTotal={true}
          thead={
            <tr>
              <th>
                <Checkbox id={'checkAll'} onChange={() => {}} />
              </th>
              <th>사업명</th>
              <th>사업지</th>
              <th>발주처</th>
              <th>현재 / 총 회차</th>
              <th>진행</th>
              <th>기간</th>
            </tr>
          }
          tbody={
            <>
              {dummy.length ? (
                dummy.map((item: any) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Checkbox id={'chk_' + item.id} checked={item.checked} onChange={() => {}} />{' '}
                      </td>
                      <td>
                        <TdEllipsis str={item.name} />
                      </td>
                      <td>
                        <TdEllipsis str={item.address} />
                      </td>
                      <td>{item.client}</td>
                      <td>
                        <RoundBadge num={item.round} />
                      </td>
                      <td>{item.progress}</td>
                      <td>
                        {item.startDate} ~ {item.endDate}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7} rowSpan={10} style={{ height: 600 }}>
                    <NoData text={'등록된 사업장이 없습니다.'} icon={IcoNoProject} color={'#3F3F3F'} />
                  </td>
                </tr>
              )}
            </>
          }
        />
      }
    />
  );
};

export default WorkPlaceList;
