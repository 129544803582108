import { useQuery } from '@tanstack/react-query';
import { uploadImages } from '../service/image';
import useMutateHook from '../../useMutateHook';

//사진파일 업로드
export function useUploadImages(files) {
  return useMutateHook({
    fetchApi: () => uploadImages(files),
    retry: 3,
  });
}
