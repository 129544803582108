import { useQuery } from '@tanstack/react-query';
import queryKeys from '../keys/queryKeys';
import api from '../service';

// 유저의 사업 리스트 조회
export function useGetProjects(memberId) {
  return useQuery({
    queryKey: [queryKeys.memberKeys.projects(memberId)],
    queryFn: () => api.member.getProjects(memberId),
  });
}
