import React, { useEffect, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import NoData from '../components/atoms/NoData';
import IcoLot from '../assets/images/noData/ico-lot.svg';
import PagingTable from '../components/atoms/PagingTable';
import Checkbox from '../components/atoms/Checkbox';
import SearchInput from '../components/molcures/SearchInput';
import { useRecoilState } from 'recoil';
import { confirmState, formState } from '../store/Atoms';
import LabelInput from '../components/atoms/LabelInput';
import { Section } from '../components/atoms/TabContent';
import LabelSelect from '../components/atoms/LabelSelect';

const Content = {
  Inner: styled.div`
    padding: 24px 0;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    > * {
      flex: 1;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
};

const initForm = {
  email: '',
  name: '',
  password: '',
  passwordConfirm: '',
};

const PopupSignUpReader = ({ onClose }) => {
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [form, setForm] = useRecoilState(formState);

  // 등록하기 버튼 disabled 값
  const isDisabled = () => {
    return !(form.email && form.name && form.client && form.password && form.password === form.passwordConfirm);
  };

  useEffect(() => {
    setForm(initForm);
  }, []);

  //등록하기 버튼 클릭
  const onAdd = () => {
    setConfirm({
      ...confirmState,
      show: true,
      message: '등록하시겠습니까?',
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        //Todo 판독자 등록 API 작성 예정
        onClose();
        setConfirm({ ...confirm, show: false });
      },
    });
  };
  return (
    <PopupTemplate
      title={'판독자 등록'}
      width={502}
      isDimmed={true}
      onClose={onClose}
      footer={<Button width={'full'} text={'등록하기'} onClick={onAdd} disabled={isDisabled()} />}
      content={
        <Content.Inner>
          <Section>
            <LabelInput
              label={'이메일(ID)'}
              $labelWidth={126}
              inputWidth={217}
              inputHeight={48}
              required={true}
              placeholder={'이메일을 입력하세요'}
              value={form?.email || ''}
              onChange={(e) => {
                setForm({ ...form, email: e.target.value });
              }}
              rightButton={
                <Button
                  $buttonType={'medium'}
                  text={'중복 확인'}
                  width={92}
                  height={48}
                  style={{ marginLeft: 6 }}
                  onClick={() => {
                    //TODO 중복확인 API
                  }}
                />
              }
            />
          </Section>
          <Section>
            <LabelInput
              label={'이름'}
              $labelWidth={126}
              inputWidth={315}
              inputHeight={48}
              required={true}
              placeholder={'이름을 입력하세요'}
              value={form?.name || ''}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
            />
          </Section>
          <Section>
            <LabelInput
              type={'password'}
              label={'비밀번호'}
              $labelWidth={126}
              inputWidth={315}
              inputHeight={48}
              required={true}
              placeholder={'비밀번호를 입력하세요'}
              value={form?.password || ''}
              onChange={(e) => {
                setForm({ ...form, password: e.target.value });
              }}
            />
          </Section>
          <Section>
            <LabelInput
              type={'password'}
              label={'비밀번호 확인'}
              $labelWidth={126}
              inputWidth={315}
              inputHeight={48}
              required={true}
              placeholder={'비밀번호 확인'}
              value={form?.passwordConfirm || ''}
              onChange={(e) => {
                setForm({ ...form, passwordConfirm: e.target.value });
              }}
            />
          </Section>
        </Content.Inner>
      }
    />
  );
};

export default PopupSignUpReader;
