import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Input from './Input';
import { LabelInputProps, LabelInputStyle } from './LabelInput';
import Select from './Select';

const LabelSelect = ({
  $labelWidth,
  fontSize,
  marginbottom,
  $alignItem,
  label,
  value,
  padding,
  border,
  inputWidth,
  inputHeight,
  required,
  placeholder,
  onChange,
  option,
  $borderColor,
  subText,
}: LabelInputProps & { option: { text: string; value: string | number; active: boolean }[]; $borderColor?: string; subText?: string }) => {
  return (
    <LabelInputStyle.Wrap marginbottom={marginbottom} $alignItem={$alignItem} border={border} padding={padding}>
      <LabelInputStyle.Key $labelWidth={$labelWidth} fontSize={fontSize} required={required}>
        {label}
      </LabelInputStyle.Key>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select width={inputWidth} height={inputHeight} value={value} option={option} onChange={onChange} placeHolder={placeholder} $borderColor={$borderColor} />
        {subText ? <LabelInputStyle.SubText>{subText}</LabelInputStyle.SubText> : null}
      </div>
    </LabelInputStyle.Wrap>
  );
};

export default LabelSelect;
