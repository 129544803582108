import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import LabelSelect from '../../../../components/atoms/LabelSelect';
import { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import HelpMessage from '../../../../components/atoms/HelpMessage';
import IcoHelp from '../../../../assets/images/etc/ico-help-message.svg';
import Button from '../../../../components/atoms/Button';
import IcoFileUpload from '../../../../assets/images/button/ico-file-upload.svg';
import ScrollTable from '../../../../components/atoms/ScrollTable';
import Checkbox from '../../../../components/atoms/Checkbox';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';
import { useRecoilState } from 'recoil';
import { confirmState, formState } from '../../../../store/Atoms';
import NoData from '../../../../components/atoms/NoData';
import IcoAddUser from '../../../../assets/images/noData/ico-user-add.svg';
import FileButton from '../../../../components/atoms/FileButton';
import IcoFileRemove from '../../../../assets/images/button/ico-file-remove.svg';
import { PagingTableStyle } from '../../../../components/atoms/PagingTable';
import TdEllipsis from '../../../../components/atoms/TdEllipsis';
import PopupAddReader from '../../../../popup/PopupAddReader';
import PopupAddInspector from '../../../../popup/PopupAddInspector';

const Step3 = ({ onNextStep }) => {
  const [form, setForm] = useRecoilState(formState);
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [isAllCheck1, setIsAllCheck1] = useState(false);
  const [isAllCheck2, setIsAllCheck2] = useState(false);
  const [dummy1, setDummy1] = useState([
    { id: 1, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 2, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 3, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 4, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 5, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 6, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 7, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 8, name: '김무브', email: 'move@movements.kr', checked: false },
  ]);
  const [dummy2, setDummy2] = useState([
    { id: 1, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 2, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 3, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 4, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 5, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 6, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 7, name: '김무브', email: 'move@movements.kr', checked: false },
    { id: 8, name: '김무브', email: 'move@movements.kr', checked: false },
  ]);

  //팝업 상태관리
  const [popupState, setPopupState] = useState({
    reader: false,
    inspector: false,
  });

  const [option, setOption] = useState([
    { text: '1', value: 1, active: false },
    { text: '2', value: 2, active: false },
    { text: '3', value: 3, active: false },
    { text: '4', value: 4, active: false },
    { text: '5', value: 5, active: false },
  ]);

  //탭 변경시 form에 저장된 값 가져와서 option 선택
  useEffect(() => {
    const newArr = [...option];
    newArr.map((i) => {
      i.active = String(form.round) === String(i.value);
    });
    setOption(newArr);
  }, []);

  //전체 선택/해제
  const onCheckAll = (type) => {
    const newArr = type === 1 ? [...dummy1] : [...dummy2];
    if (newArr.length === newArr.filter(({ checked }) => checked).length) {
      newArr.map((i) => {
        i.checked = false;
      });
    } else {
      newArr.map((i) => {
        i.checked = true;
      });
    }
    type === 1 ? setDummy1(newArr) : setDummy2(newArr);
  };

  //체크박스 클릭
  const onCheck = (type, id) => {
    const newArr = type === 1 ? [...dummy1] : [...dummy2];
    newArr.find((i) => i.id === id)!.checked = !newArr.find((i) => i.id === id)?.checked;
    type === 1 ? setDummy1(newArr) : setDummy2(newArr);
  };

  //체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    if (dummy1.filter(({ checked }) => checked).length === dummy1.length) {
      setIsAllCheck1(true);
    } else {
      setIsAllCheck1(false);
    }
  }, [dummy1.filter(({ checked }) => checked).length]);

  //체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    if (dummy2.filter(({ checked }) => checked).length === dummy2.length) {
      setIsAllCheck2(true);
    } else {
      setIsAllCheck2(false);
    }
  }, [dummy2.filter(({ checked }) => checked).length]);

  //판독자를 추가/제거 했을때 리스트를 form에 담음
  useEffect(() => {
    const newArr: any = [];
    dummy1.map((i) => {
      newArr.push(i.id);
    });
    setForm({ ...form, reader: newArr });
  }, [dummy1.length]);

  //검수자를 추가/제거 했을때 리스트를 form에 담음
  useEffect(() => {
    const newArr: any = [];
    dummy2.map((i) => {
      newArr.push(i.id);
    });
    setForm({ ...form, inspector: newArr });
  }, [dummy2.length]);

  //불러온 파일 삭제(컨펌창 이벤트)
  const onConfirm = () => {
    setConfirm({
      ...confirm,
      message: '템플릿 파일을 삭제하시겠습니까?',
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        const fileEl = document.getElementById('tempOpen') as HTMLInputElement;
        fileEl.value = '';
        setForm({ ...form, template: null });
        setConfirm({ ...confirm, show: false });
      },
      show: true,
    });
  };

  //팝업 토글
  const onTogglePopup = (key) => {
    setPopupState({ ...popupState, [key]: !popupState[key] });
  };
  return (
    <>
      <Section>
        <LabelSelect
          label={'판독 회차'}
          inputWidth={112}
          inputHeight={48}
          required={true}
          // placeholder={'사업장명을 입력하세요'}
          option={option}
          $borderColor={'#E5E5EC'}
          onChange={(e) => {
            const newArr = [...option];
            newArr.map((i) => {
              i.active = String(i.value) === e.target.ariaValueText;
            });
            setForm({ ...form, round: e.target.ariaValueText });
            setOption(newArr);
          }}
          subText={'회차까지 실행'}
        />
      </Section>
      <Section>
        <LabelInputStyle.Wrap>
          <LabelInputStyle.Key>판독 결과 템플릿</LabelInputStyle.Key>
          <div>
            <HelpMessage icon={IcoHelp} message={'판독 결과를 출력할 템플릿 파일을 <b>[CSV 포맷]</b>으로 업로드하세요. 업로드하지 않은 경우 기본 포맷으로 출력됩니다.'} style={{ marginBottom: 16 }} />
            <div style={{ display: 'flex' }}>
              <FileButton
                id={'tempOpen'}
                text={'파일 불러오기'}
                onChange={(e) => {
                  if (e.target.files?.length) {
                    setForm({ ...form, template: e.target.files[0] });
                  }
                }}
                $isShowFileName={true}
                fileName={form.template?.name || ''}
              />
              {form.template ? <Button $colorType={'redLine'} text={`파일 삭제`} icon={IcoFileRemove} $iconSize={20} $iconSpacing={6} style={{ marginLeft: 6 }} padding={12} height={36} fontSize={14} onClick={onConfirm} /> : null}
            </div>
          </div>
        </LabelInputStyle.Wrap>
      </Section>
      <Section style={{ height: 'calc(100% - 284px)' }}>
        <LabelInputStyle.Wrap style={{ height: '100%' }}>
          <LabelInputStyle.Key>판독자 / 검수자</LabelInputStyle.Key>
          <div style={{ width: 1116, height: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              {/* 판독자 */}
              <div
                style={{
                  flex: 1,
                  paddingRight: 16,
                  boxSizing: 'border-box',
                  borderRight: '1px dashed #D9D9D9',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 14,
                  }}
                >
                  <span style={{ color: '#00B074' }}>판독자</span>
                  <Button $buttonType={'medium'} text={'판독자 추가'} icon={IcoFileUpload} $iconSize={20} $iconSpacing={6} onClick={() => onTogglePopup('reader')} />
                </div>
                <ScrollTable
                  caption={'판독자'}
                  colgroup={[60, 100, 'auto', 100]}
                  height={'calc(100% - 50px)'}
                  thead={
                    <tr>
                      <th scope={'col'}>
                        <Checkbox onChange={() => onCheckAll(1)} id={'checkAll1'} checked={isAllCheck1} />
                      </th>
                      <th scope={'col'}>이름</th>
                      <th scope={'col'}>이메일</th>
                      <th scope={'col'}></th>
                    </tr>
                  }
                  tbody={
                    <>
                      {dummy1.length ? (
                        dummy1.map((i: any) => {
                          return (
                            <tr key={i.id} className={i.checked ? 'checked' : undefined}>
                              <td>
                                <Checkbox id={'reader_' + i.id} checked={i.checked} onChange={() => onCheck(1, i.id)} />
                              </td>
                              <td>{i.name}</td>
                              <td>
                                <TdEllipsis str={i.email} />
                              </td>
                              <td>
                                <PagingTableStyle.BtnRowRemove className={`btnRemove ${i.checked ? 'checked' : undefined}`} type={'button'}></PagingTableStyle.BtnRowRemove>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            <NoData text={'판독자를 추가하세요.'} icon={IcoAddUser} $iconSize={54} color={'#3F3F3F'} />
                          </td>
                        </tr>
                      )}
                    </>
                  }
                />
              </div>
              {/* 판독자 끝 */}

              {/* 검수자 */}
              <div style={{ flex: 1, paddingLeft: 16, boxSizing: 'border-box' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flex: 1,
                    marginBottom: 14,
                  }}
                >
                  <span style={{ color: '#00B074' }}>검수자</span>
                  <Button $buttonType={'medium'} text={'검수자 추가'} icon={IcoFileUpload} $iconSize={20} $iconSpacing={6} onClick={() => onTogglePopup('inspector')} />
                </div>
                <ScrollTable
                  caption={'검수자'}
                  colgroup={[60, 100, 'auto', 100]}
                  height={'calc(100% - 50px)'}
                  thead={
                    <tr>
                      <th scope={'col'}>
                        <Checkbox onChange={() => onCheckAll(2)} id={'checkAll2'} checked={isAllCheck2} />
                      </th>
                      <th scope={'col'}>이름</th>
                      <th scope={'col'}>이메일</th>
                      <th scope={'col'}></th>
                    </tr>
                  }
                  tbody={
                    <>
                      {dummy2.length ? (
                        dummy2.map((i: any) => {
                          return (
                            <tr key={i.id} className={i.checked ? 'checked' : undefined}>
                              <td>
                                <Checkbox id={'inspector_' + i.id} checked={i.checked} onChange={() => onCheck(2, i.id)} />
                              </td>
                              <td>{i.name}</td>
                              <td>
                                <TdEllipsis str={i.email} />
                              </td>
                              <td>
                                <PagingTableStyle.BtnRowRemove className={`btnRemove ${i.checked ? 'checked' : undefined}`} type={'button'}></PagingTableStyle.BtnRowRemove>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            <NoData text={'검수자를 추가하세요.'} icon={IcoAddUser} $iconSize={54} color={'#3F3F3F'} />
                          </td>
                        </tr>
                      )}
                    </>
                  }
                />
              </div>
              {/* 검수자 끝 */}
            </div>
            <NextStep>
              <Button $buttonType={'large'} text={'다음 단계'} $icoPosition={'right'} icon={IcoStepNext} $iconSize={16} $iconSpacing={6} onClick={() => onNextStep(3)} />
            </NextStep>
          </div>
        </LabelInputStyle.Wrap>
      </Section>
      {popupState.reader ? <PopupAddReader onClose={() => onTogglePopup('reader')} /> : null}
      {popupState.inspector ? <PopupAddInspector onClose={() => onTogglePopup('inspector')} /> : null}
    </>
  );
};

export default Step3;
