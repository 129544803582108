import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import IcoSelectDown from '../../assets/images/select/ico-select-down.svg';
import IcoSelectUp from '../../assets/images/select/ico-select-up.svg';
import IcoSelected from '../../assets/images/select/ico-select-selected.svg';
import IcoSiteMarker from '../../assets/images/select/ico-site-marker.svg';

export const SelectStyle = {
  Area: styled.div`
    display: flex;
    align-items: center;
  `,
  Wrap: styled.div<SelectProps>`
    position: relative;
    /* z-index: 10; */
    width: ${(p) => (p.width === 'full' ? '100%' : typeof Number(p.width) === 'number' ? p.width + 'px' : 'auto')};
  `,
  Selected: styled.strong<SelectProps>`
    position: relative;
    z-index: 99;
    display: block;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-sizing: border-box;
    height: ${(p) => (p.height ? p.height + 'px' : '48px')};
    line-height: ${(p) => (p.height ? p.height + 'px' : '48px')};
    color: #001a21;
    background: #fff url(${IcoSelectDown}) no-repeat right 16px center;
    border-radius: ${(p) => (p.$isHeader ? '0 4px 4px 0' : '4px')};
    font-weight: 500;
    font-size: 16px;
    padding: ${(p) => (p.$isHeader ? '0 50px 0 48px' : '0 50px 0 16px')};
    transition: 0.4s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid ${(p) => (p.$borderColor ? p.$borderColor : 'transparent')};
    ${(p) =>
      p.$isHeader &&
      `
    &:before {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 24px;
      height: 24px;
      display: block;
      background: url(${IcoSiteMarker}) no-repeat center;
    }
      `}
    &.active {
      background: #fff url(${IcoSelectUp}) no-repeat right 16px center;
      box-shadow: 0 5px 8px rgba(210, 236, 227, 1);
      border-radius: 4px 4px 0 0;
      + div {
        display: block;
        z-index: 100;
      }
    }

    &.disabled {
      background-color: #ececec;
    }

    &:focus-within {
      border: 1px solid #00b074;

      &.disabled {
        border: 1px solid transparent;
        background-color: #ececec;
      }
    }

    &:hover {
      background-color: #e5f8e8;

      &.disabled {
        background-color: #ececec;
      }
    }
  `,
  Label: styled.div<{ height?: number }>`
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid transparent;
    height: ${(p) => (p.height ? p.height + 'px' : '48px')};
    background: #fff;
    color: #767676;
    font-size: 14px;
    font-weight: 500;
  `,
  ListWrap: styled.div<{ $borderColor?: string }>`
    position: absolute;
    z-index: 10;
    left: 0;
    top: 100%;
    display: none;
    width: 100%;
    border: 1px solid ${(p) => (p.$borderColor ? p.$borderColor : 'transparent')};
    background: #fff;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
  `,
  Search: styled.div`
    padding: 16px 16px 10px;

    input {
      background: #fff no-repeat right 20px center;
      margin: 0 auto;
      border: 1px solid #e2e2e2 !important;
    }
  `,
  List: styled.ul`
    max-height: 200px;
    overflow-y: auto;
  `,
  Item: styled.li<SelectProps>`
    height: ${(p) => (p.height ? p.height + 'px' : '48px')};
    line-height: ${(p) => (p.height ? p.height + 'px' : '48px')};
    font-size: 16px;
    font-weight: 400;
    padding: 0 16px;
    cursor: pointer;
    color: #001a21;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.active {
      font-weight: 600;
      background: #d1f1e6 url(${IcoSelected}) no-repeat right 16px center;
    }
    &:hover,
    &:focus {
      background: #e5f8e8;
      outline: none;
      &.active {
        font-weight: 600;
        background: #d1f1e6 url(${IcoSelected}) no-repeat right 16px center;
      }
    }
  `,
};
export interface SelectProps {
  width?: 'full' | number;
  height?: number;
  option: { text: string; value: string | number; active: boolean }[] | [];
  style?: object;
  trigger?: any;
  value?: string | number;
  disabled?: boolean;
  onTextChange?: React.MouseEventHandler<HTMLLIElement>;
  onChange?: (e: React.MouseEvent<HTMLLIElement>, name?: string) => void;
  name?: string;
  search?: boolean;
  id?: string;
  labelTrigger?: number;
  placeHolder?: string;
  $isHeader?: boolean;
  $borderColor?: string;
}
const Select = ({ width, height, option = [], onChange, style, value, disabled, name, search, id, labelTrigger, placeHolder, $isHeader, $borderColor }: SelectProps) => {
  const comboRef = useRef<HTMLDivElement>(null); //셀렉트 박스 영역
  const listRef = useRef<any>([]);
  const [selected, setSelected] = useState<string>(option.filter(({ active }) => active)[0]?.text); //현재 선택된 옵션의 텍스트
  const [toggle, setToggle] = useState(false); //셀렉트박스 on/off
  const [word, setWord] = useState('');
  const searchRef = useRef(null);
  // 선택한 텍스트로 변경
  const onTextChange = (e: React.MouseEvent) => {
    // const eventTarget = e.target as HTMLLIElement;
    // console.log(eventTarget.innerText);
    // setSelected(eventTarget.innerText);
  };

  useEffect(() => {
    setSelected(option.filter((i) => i.active)[0]?.text);
  }, [option]);

  // useEffect(() => {
  //     if(labelTrigger === 0){
  //         setToggle(false)
  //     }else{
  //         setToggle(true)
  //         setTimeout(() => listRef.current[0].focus())
  //     }
  // },[labelTrigger])

  const onToggle = () => {
    if (!disabled) {
      setToggle(!toggle);
    }
  };
  //외부영역 클릭시 셀렉트박스 닫기
  const handleClickOutSide = (e: MouseEvent) => {
    if (toggle && !comboRef.current?.contains(e.target as Element)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (toggle) document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const onFocusing = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setToggle(true);
    } else if (e.key === 'ArrowDown') {
      if (toggle) {
        listRef.current[0].focus();
      } else {
        setToggle(true);
      }
    }
  };
  const onNext = (e: any, index: number) => {
    if (e.key === 'ArrowDown') {
      const length = option?.filter(({ text }) => text?.includes(word)).length;
      if (length > index + 1) {
        listRef.current[index + 1].focus();
      }
    } else if (e.key === 'ArrowUp') {
      if (index > 0) {
        listRef.current[index - 1].focus();
      }
    } else if (e.key === 'Enter') {
      onChange?.(e, name);
      onTextChange(e);
      onToggle();
      if (search) {
        (searchRef.current as any).value = '';
        setWord('');
      }
    }
  };

  return (
    <>
      <SelectStyle.Wrap ref={comboRef} width={width} style={style} option={option}>
        <SelectStyle.Selected
          $isHeader={$isHeader}
          $borderColor={$borderColor}
          id={id}
          tabIndex={0}
          height={height}
          onClick={onToggle}
          labelTrigger={labelTrigger}
          onKeyDown={(e) => onFocusing(e)}
          option={option}
          className={toggle ? 'active' : disabled ? 'disabled' : undefined}
        >
          {placeHolder && !selected ? <span style={{ color: '#94949A' }}>{placeHolder}</span> : null}
          {selected}
        </SelectStyle.Selected>
        <SelectStyle.ListWrap $borderColor={$borderColor}>
          <SelectStyle.List className={'cScroll'}>
            {option.map(({ text, value, active }: any, index) => {
              return (
                <SelectStyle.Item
                  key={index}
                  aria-valuetext={value}
                  tabIndex={0}
                  ref={(e: any) => {
                    listRef.current[index] = e;
                  }}
                  onClick={(e) => {
                    onChange?.(e, name);
                    onTextChange(e);
                    onToggle();
                  }}
                  onKeyDown={(e) => onNext(e, index)}
                  onMouseOver={() => {
                    listRef.current[index].focus();
                  }}
                  option={option}
                  className={active ? 'active' : undefined}
                >
                  {text}
                </SelectStyle.Item>
              );
            })}
          </SelectStyle.List>
        </SelectStyle.ListWrap>
      </SelectStyle.Wrap>
    </>
  );
};

export default Select;
