import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IcoImgDup from 'assets/images/map/ico-img-duplicate.svg';
import IcoImgUpload from 'assets/images/map/ico-img-upload.svg';
import PopupDuplicationPic from 'popup/PopupDuplicationPic';
import Button from 'components/atoms/Button';
import UserSetting from './UserSetting';
import { ButtonStyle } from '../../atom/_style';
import { useUploadImages } from '../../../hooks/RQuery/utils/image';
import query from '../../../hooks/RQuery/utils';
import { useRecoilState } from 'recoil';
import { confirmState, loadingState } from '../../../store/Atoms';
import { useQueryClient } from '@tanstack/react-query';
import PopupFailUpload from '../../../popup/PopupFailUpload';
import Confirm from '../Confirm';

const TopBtnGroupStyle = {
  Wrap: styled.div`
    display: flex;
    gap: 8px;
    position: fixed;
    top: 16px;
    right: 18px;
    z-index: 12;
  `,
  ImgBtnWrap: styled.div`
    padding: 0 2px 0 12px;
    background-color: #00b074;
    border-radius: 4px;
    display: flex;
    align-items: center;
    > .ico {
      display: block;
      width: 24px;
      height: 24px;
      background: url(${IcoImgUpload}) no-repeat center;
    }
    button,
    label {
      cursor: pointer;
      color: #fff;
      font-weight: 500;
      padding: 0 12px;
      &:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
      }
      &:hover {
        transition: all 0.4s;
        color: #ffea00;
      }
    }
  `,
};
const UploadFileStyle = {
  Wrap: styled.div`
    position: relative;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  `,
  Input: styled.input`
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  `,
  Label: styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    box-sizing: border-box;
    border: 1px solid transparent;
    color: #fff;
    width: 148px;
    height: 48px;
    padding: 0 10px;
    font-size: 16px;
    border-radius: 4px;
    background: #00b074;
    cursor: pointer;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url(${IcoImgUpload}) no-repeat center;
      margin-right: 6px;
    }
    &:hover {
      background: #008d5d;
    }
    &:active {
      background: #0e7d5f;
    }
  `,
};
function TopBtnGroup() {
  const [loading, setLoading] = useRecoilState(loadingState);
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [filesArray, setFilesArray] = useState<File[][]>([]);
  const [curIdx, setCurIdx] = useState(0);
  const [errorList, setErrorList] = useState<string[]>([]);
  const fileUpload = query.image.useUploadImages(fileList);

  //팝업
  const [popupState, setPopupState] = useState({
    errorList: false,
  });

  //팝업 토글
  const onTogglePopup = (key: string) => {
    const newState = { ...popupState };
    newState[key] = !newState[key];
    setPopupState(newState);
  };

  //사진 업로드(동기)
  const onChange = (e) => {
    setCurIdx(0);
    const files: File[] = [...e.target.files];
    const fileGroup: File[][] = [];
    files.map((file, index) => {
      if (index % 3 === 0) {
        fileGroup.push([file]);
      } else {
        fileGroup[fileGroup.length - 1].push(file);
      }
    });
    setFilesArray(fileGroup);
    setFileList(fileGroup[0]);
    setLoading({ show: true });
  };

  //사진 업로드 동기처리
  useEffect(() => {
    if (fileList.length) {
      const loop = async () => {
        try {
          await fileUpload.mutateAsync(fileList, {
            onSuccess: (res: any) => {
              //실패한 파일명 에러리스트에 추가
              const errList: any = [...errorList];
              res.datas.map((i) => {
                errList.push(i);
              });
              setErrorList(errList);

              //다음파일 호출
              setCurIdx(curIdx + 1);
              if (filesArray.length > curIdx + 1) {
                setFileList(filesArray[curIdx + 1]);
              } else {
                setLoading({ show: false });
                //실패 항목이 있을때 실패 팝업
                if (errorList.length) {
                  setPopupState({
                    ...popupState,
                    errorList: true,
                  });
                } else {
                  //실패가 없으면 얼럿창
                  setConfirm({
                    ...confirm,
                    show: true,
                    message: '사진 업로드가 완료되었습니다.',
                    rightFunc: () => setConfirm({ ...confirm, show: false }),
                    rightText: '닫기',
                  });
                }
              }
            },
          });
        } catch (error) {
          console.error('Error uploading files:', error);
        }
      };
      loop();
    }
  }, [fileList]);

  return (
    <>
      <TopBtnGroupStyle.Wrap>
        <TopBtnGroupStyle.ImgBtnWrap>
          <span className="ico"></span>
          <UploadFileStyle.Wrap>
            <UploadFileStyle.Input id={'fileUpload'} type={'file'} multiple={true} onChange={onChange} accept={'image/jpeg'} />
            <label htmlFor={'fileUpload'}>사진 업로드</label>
          </UploadFileStyle.Wrap>
          <button onClick={() => setOpenDuplicate(true)}>사진 중복확인</button>
          <button>사진 결과 확인</button>
        </TopBtnGroupStyle.ImgBtnWrap>

        {/* 사진 중복확인 팝업 */}
        {openDuplicate && <PopupDuplicationPic onClose={() => setOpenDuplicate(false)} />}

        {/* 유저정보 버튼 - 로그아웃 등 */}
        <UserSetting />
        {/*  */}
      </TopBtnGroupStyle.Wrap>
      {popupState.errorList ? <PopupFailUpload errorList={errorList} onClose={() => onTogglePopup('errorList')} failType={'pic'} /> : null}
      {confirm.show ? <Confirm /> : null}
    </>
  );
}

export default TopBtnGroup;
