import OlCore from '../OlCore';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import FarmMapModule from '../Layer/FarmMapModule';
import Style from 'ol/style/Style';
import { Fill } from 'ol/style.js';
import { FARM_STYLE } from '../Style/PolygonStyle';
import Select from 'ol/interaction/Select.js';
import { click } from 'ol/events/condition';
import { Overlay } from 'ol';

class ObjectFarmMap extends FarmMapModule {
  private Farm01Layer!: VectorLayer<VectorSource>;
  private Farm01Source: VectorSource = new VectorSource();
  private clickEvent = new Select();
  private lotOverlay: Overlay = new Overlay({
    positioning: 'bottom-center',
    element: document.getElementById('lotOverlay') as any,
  });
  constructor(core: OlCore) {
    super(core);

    //팜맵
    this.Farm01Layer = new VectorLayer<VectorSource>({
      properties: {
        id: 'Farm01',
      },
      visible: true,
      zIndex: 90,
      // minZoom: 16.5,
      // maxZoom: Infinity,
      style: (feature, resolution) => FARM_STYLE.DEFAULT(feature, resolution),
    });
    this.core.mapInstance.addOverlay(this.lotOverlay);
    this.setLayers([this.Farm01Layer]);
  }

  public draw(data) {
    const allData = data.datas;
    const polygonGroup: any = [];
    allData.map((i) => {
      const datas = i.output.farmmapData.data;
      console.log(datas);
      datas.map((geoms) => {
        geoms.geometry.map((geom) => {
          const newArr: any = [];
          geom.xy.map((coords: any) => {
            newArr.push([coords.x, coords.y]);
          });
          polygonGroup.push([[newArr]]);
        });
      });
    });
    const features = this.createFarmMapFeatures(polygonGroup);
    this.Farm01Source = this.createSource(features);
    this.Farm01Layer.setSource(this.Farm01Source);
    this.core.mapInstance.getView().fit(this.Farm01Source.getExtent(), { duration: 400 });
  }

  public addClickFeature(callback?: any) {
    this.core.mapInstance.removeInteraction(this.clickEvent); // 초기화
    this.clickEvent = new Select({
      condition: click,
      layers: [this.Farm01Layer],
      style: (feature, resolution) => FARM_STYLE.ACTIVE(feature, resolution),
    });

    this.clickEvent.on('select', (e) => {
      const targetCollection = e.target.getFeatures().getArray();
      if (targetCollection.length) {
        // 콜백 함수 TODO 필지정보 형태 확정시 callback 작업 예정
        // callback(targetCollection[0].getProperties().properties);

        //선택한 폴리곤의 중심점 값
        const interiorPoint = targetCollection[0].getGeometry().getInteriorPoints().getCoordinates()[0];

        //오버레이 위치 이동
        this.lotOverlay.setPosition(interiorPoint);
        this.core.mapInstance.getView().fit([interiorPoint[0], interiorPoint[1], interiorPoint[0], interiorPoint[1]], { duration: 400, maxZoom: this.core.mapInstance.getView().getZoom() });
      } else {
        //콜백 함수
        // callback(null);
        //오버레이 위치 제거
        this.lotOverlay.setPosition(undefined);
      }
    });

    this.core.mapInstance.addInteraction(this.clickEvent);
  }

  public clearClickFeature() {
    this.clickEvent.getFeatures().clear();
    this.lotOverlay.setPosition(undefined);
  }
}

export default ObjectFarmMap;
