import React from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Input from '../components/atoms/Input';

const Content = {
  Inner: styled.div`
    padding: 32px 0;
  `,
  Tip: styled.p`
    color: #00b074;
    font-size: 14px;
    font-weight: 400;
  `,
};

const PopupPasswordChange = ({ onClose }) => {
  return (
    <PopupTemplate
      title={'비밀번호 변경'}
      width={352}
      isDimmed={true}
      onClose={onClose}
      footer={<Button width={'full'} text={'비밀번호 변경'} />}
      content={
        <Content.Inner>
          <Input type={'password'} placeholder={'새 비밀번호'} width={'100%'} height={48} style={{ marginBottom: 8 }} />
          <Input type={'password'} placeholder={'새 비밀번호 확인'} width={'100%'} height={48} style={{ marginBottom: 8 }} />
          <Content.Tip>* 8~10자 이내로 영문, 숫자, 특수문자를 모두 사용</Content.Tip>
        </Content.Inner>
      }
    />
  );
};

export default PopupPasswordChange;
