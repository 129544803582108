import { View, Map, Overlay, Feature } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { XYZ, OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { FitOptions } from 'ol/View';
import { ScaleLine } from 'ol/control';
import { koCenter, koExt } from './util';
import * as interactions from 'ol/interaction';

//코어를 담당하는 mapInstance Viewer객체
//Viewr객체의 기능
class OlCore {
  private SATELLITE_URL = 'http://mt0.google.com/vt/lyrs=s&hl=ko&x={x}&y={y}&z={z}';
  private GOOGLE_ROADS = 'http://mt0.google.com/vt/lyrs=h&hl=ko&x={x}&y={y}&z={z}';
  private GOOGLE_STANDARD = 'http://mt0.google.com/vt/lyrs=m&hl=ko&x={x}&y={y}&z={z}';
  private GOOGLE_TERRAIN = 'http://mt0.google.com/vt/lyrs=p&hl=ko&x={x}&y={y}&z={z}';
  private GOOGLE_SAR = 'http://mt0.google.com/vt/lyrs=r&hl=ko&x={x}&y={y}&z={z}'; //somehow altered roadmap
  private GOOGLE_HYBRID = 'http://mt0.google.com/vt/lyrs=y&hl=ko&x={x}&y={y}&z={z}';

  public googleMapSource = new XYZ({ url: this.SATELLITE_URL });
  public roadMapSource = new XYZ({ url: this.GOOGLE_ROADS });
  public standardMapSource = new XYZ({ url: this.GOOGLE_STANDARD });
  public terrainMapSource = new XYZ({ url: this.GOOGLE_TERRAIN });
  public sarMapSource = new XYZ({ url: this.GOOGLE_SAR });
  public hybridMapSource = new XYZ({ url: this.GOOGLE_HYBRID });
  public osmMapSource = new OSM();

  // 구글 위성 지도
  public googleMapLayer = new TileLayer({
    source: this.googleMapSource,
    properties: { name: 'google-map-satellite' },
    zIndex: 1,
    preload: Infinity,
  });

  // 기본 2D 지도
  public osmMapLayer = new TileLayer({
    source: this.osmMapSource,
    properties: { name: 'osm-map' },
    zIndex: 1,
    preload: Infinity,
  });

  public mapInstance: Map;

  public viewer: View;

  //지도 초기화
  constructor() {
    this.mapInstance = new Map({
      target: 'mapContainer',
      layers: [this.googleMapLayer],
      view: new View({
        center: [985891.2211626049, 1783554.8932067694],
        extent: [293879.82339412393, 1436992.6167908115, 1677902.6189310858, 2130117.169622727],
        zoom: 7.751621106338325,
        // maxZoom: 20,
        projection: 'EPSG:5179',
        //   projection: 'EPSG:3857',
      }),
      interactions: interactions.defaults({ doubleClickZoom: false }),
    });

    this.viewer = this.mapInstance.getView();
    this.mapInstance.addControl(
      new ScaleLine({
        target: document.getElementById('scaleBar') as any,
      }),
    );

    // // TEST: 임시로 경기도 fit
    // const extent = [163543.9768328511, 566755.3499597321, 172595.94852637654, 573468.846297507];
    // this.viewer.fit(extent, {});
  }
  //해당 레이어로 화면이동
  public moveToLayer(layer?: VectorLayer<VectorSource>, option?: FitOptions) {
    if (layer?.getSource()?.getExtent()[0] !== Infinity) {
      layer && this.viewer.fit(layer.getSource()?.getExtent() || [], option);
    }
  }

  public moveToFeature(feature?: Feature, option?: FitOptions) {
    if ((feature?.getGeometry() as any).getExtent() !== Infinity) {
      feature && this.viewer.fit(feature.getGeometry()?.getExtent() || [], option);
    }
  }
  public getMapInstance() {
    return this.mapInstance;
  }

  //지도 이동 후 스케일바 조절
  public onMoveEnd(callback: (e) => void) {
    this.mapInstance.on('moveend', (e) => {
      callback(e);
    });
  }

  public getOverlay(id: string) {
    return this.mapInstance
      .getOverlays()
      .getArray()
      .find((i: Overlay) => i.getOptions().id === id);
  }

  public mapChange(source: XYZ | OSM) {
    this.mapInstance.getAllLayers()[0].setSource(source);
  }
  public getCenter(extent: number[]) {
    const num1 = (extent[0] + extent[2]) / 2;
    const num2 = (extent[1] + extent[3]) / 2;
    return [num1, num2];
  }
  // 해당 중심좌표로 화면 이동
  public moveToCoord(coord: number[]) {
    const size = this.mapInstance.getSize() || [];
    const position = [size[0] / 2, size[1] / 2];

    this.viewer.centerOn(coord, size, position);
  }

  //해당 레이어로 화면이동
  public toggleQuickToLayer(layerList) {
    layerList.map((i) => {
      if (i.active) {
        i.layerId.map((j) => {
          this.mapInstance
            .getAllLayers()
            .find((i) => i.get('id') === j)
            ?.setVisible(true);
        });
      } else {
        i.layerId.map((j) => {
          this.mapInstance
            .getAllLayers()
            .find((i) => i.get('id') === j)
            ?.setVisible(false);
        });
      }
    });
  }
}

export default OlCore;
